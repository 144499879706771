<template>
  <el-dialog
    :center="true"
    :title="$t('app.label.freeCallBackTitle')"
    :visible.sync="visible"
    class="cs-dialog"
  >
    <el-form
      ref="formData"
      label-position="right"
      :rules="rules"
      :model="formData"
      label-width="150px"
    >
      <el-form-item prop="contactNumber" :label="$t('app.label.contactNumber')">
        <el-input
          type="text"
          clearable=""
          v-model="formData.contactNumber"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="disable" @click="applyCallback()"
          >{{ $t("app.label.applyCallback") }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import * as api from "@/api/api";
export default {
  name: "ServiceDialog",
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      formData: {
        contactNumber: "",
        verifyCode: "",
      },
      rules: {
        contactNumber: [
          {
            trigger: "change",
            min: 11,
            max: 12,
            message: this.$t("app.msg.error.contactNumber"),
          },
        ],
      },
    };
  },
  computed: {
    disable() {
      return (
        this.formData.contactNumber.length < 11 ||
        this.formData.contactNumber.length > 12
      );
    },
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.formData.contactNumber = "";
  },
  methods: {
    applyCallback() {
      let params = {
        mobileNo: this.formData.contactNumber,
        type: 0,
      };

      api.createCallback(params, (res) => {
        const { errMsg, errCode } = res.data.head || {};
        if (errCode === "0000") {
          if (res.data.body) {
            this.$message({
              type: "success",
              message: this.$t("app.msg.success.callbackSent"),
            });
            this.formData.contactNumber = "";
            this.$emit("input", false);
          } else {
            this.$message({
              type: "error",
              message: "发送失败，请重新操作!",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: errMsg,
          });
        }
      });
    },
  },
};
</script>

<style>
</style>