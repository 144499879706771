<template>
  <div class="dialog" v-if="isModalShow">
    <div class="mask"></div>
    <div class="message">
      <div class="header">温馨提示</div>
      <div class="body">当前系统正在维护！</div>
      <div class="footer">
        <div class="cancel" @click="isModalShow = false">取消</div>
        <div class="confirm" @click="openKfUrl">联系客服</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessModal",
  data () {
    return {
      isModalShow: false,
      liveChatAddress: 'https://www.d88-livechat.com/chat/chatClient/chatbox.jsp?companyID=8987&configID=74&skillId=1'
    }
  },
  methods: {
    show () {
      this.isModalShow = true;
    },
    openKfUrl () {
      this.isModalShow = false;
      window.open(this.liveChatAddress)
    },
  }
}
</script>

<style lang="less" scoped>
.dialog {
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.75);
  }

  .message {
    width: 520px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
  }

  .header {
    height: 60px;
    line-height: 60px;
    text-align: left;
    text-indent: 20px;
    background: #44475D;
    color: #ffffff;
  }

  .body {
    background: #ffffff;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding-bottom: 30px;
    margin-top: -1px;

    .cancel, .confirm {
      width: 160px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 5px;
      margin: 0 10px;
      cursor: pointer;
    }

    .cancel {
      background: #ffffff;
      border: 1px solid #C7977C;
      color: #C7977C;
    }

    .confirm {
      background-image: linear-gradient(133deg, #C7977C 0%, #AD7A62 100%);
      border: 1px solid #C7977C;
      color: #ffffff;

    }
  }
}
</style>