// import { Toast } from 'mint-ui';
import * as api from "./api";
// import moment from 'moment';
import $ from "jquery";
import store from "../store";
import EventBus from "eventbusjs";
import { Message } from "element-ui";
import router from "@/router";
import { loginInfo } from "./api";
import Decimal from "decimal.js";

// =============
export function storeCommit(name, value) {
  store.commit(name, value);
}

// ==============
export function isEmpty(obj) {
  return Object.keys(obj).length == 0;
}

export function isNotEmpty(obj) {
  return !isEmpty(obj);
}

// export function monthFirstDayStr(input) {
//     return moment(input).startOf('month').format('YYYY-MM-DD');
// }
//
// export function monthLastDayStr(input) {
//     return moment(input).endOf('month').format('YYYY-MM-DD');
// }
//
// export function todayStr() {
//     return moment().format("YYYY-MM-DD");
// }

export function floatFormatSubFix(value) {
  let userInfo =
    typeof store.state.userInfo === "string"
      ? JSON.parse(store.state.userInfo)
      : store.state.userInfo;
  const currencyArr = ["CNY", "VND", "USDT"];
  const subfixMap = {
    VND: "VND",
    CNY: "元",
    USDT: "U",
  };
  if (currencyArr.includes(userInfo.currency)) {
    if (!value) {
      return subfixMap[userInfo.currency]
        ? "0.00" + subfixMap[userInfo.currency]
        : "0.00" + subfixMap["CNY"];
    }
    if (userInfo.currency !== "VND") {
      value = (value * 1).toFixed(2);
    }
    let num_parts = value.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (userInfo.currency === "VND") {
      return subfixMap["VND"] + num_parts[0];
    }
    if (userInfo.currency === "USDT") {
      return num_parts.join(".") + subfixMap["USDT"];
    }
    return num_parts.join(".") + subfixMap["CNY"];
  } else {
    if (!value) {
      return "0";
    }
    return (value * 1).toFixed(6);
  }
}

export function floatFormat(value) {
  let userInfo =
    typeof store.state.userInfo === "string"
      ? JSON.parse(store.state.userInfo)
      : store.state.userInfo;
  const currencyArr = ["CNY", "VND", "USDT"];
  const prefixMap = {
    VND: "VND",
    CNY: "￥",
    USDT: "＄",
  };
  if (currencyArr.includes(userInfo.currency)) {
    if (!Number(value)) {
      return prefixMap[userInfo.currency]
        ? prefixMap[userInfo.currency] + "0.00"
        : prefixMap["CNY"] + "0.00";
    }
    if (userInfo.currency !== "VND") {
      value = (value * 1).toFixed(2);
    }
    let num_parts = value.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (userInfo.currency === "VND") {
      return prefixMap["VND"] + num_parts[0];
    }
    if (userInfo.currency === "USDT") {
      return prefixMap["USDT"] + num_parts.join(".");
    }
    return prefixMap["CNY"] + num_parts.join(".");
  } else {
    if (!Number(value)) return "0";
    value = (value * 1).toFixed(6);
    let num_parts = value.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
}

export function floatFormatCurrency(value, currency, flag) {
  let newCurrency = currency || "";
  if (currency === undefined) {
    newCurrency = store.state.currentWallet.currency;
  }
  if (currency === "") {
    newCurrency = "USDT";
  }
  const Reg = /^-?\d+\.0+$/; //判断小数点后是否都为0
  const RegIsTwoDecimal = /^\d+(?:\.\d{1,2})?$/; //判断是否有两位小数
  if (!Number(value)) return "0.00";
  if (Reg.test(value)) {
    //小数点都是0保留两位小数
    value = (value * 1).toFixed(2);
  } else {
    if (["BTC", "ETH"].includes(newCurrency)) {
      // value = Math.floor(value * 1000000) / 1000000;
      value = new Decimal(value).toDecimalPlaces(6, Decimal.ROUND_DOWN);
    } else {
      value = new Decimal(value).toDecimalPlaces(2, Decimal.ROUND_DOWN);
    }
    if (RegIsTwoDecimal.test(value)) {
      value = new Decimal(value).toFixed(2);
    }
  }
  let num_parts = value.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

//根据货币类型进行格式化带货币类型返回
export function floatFormatCurrencyUnit(value, currency, flag) {
  let newCurrency = currency || "";
  if (currency === undefined) {
    newCurrency = store.state.currentWallet.currency;
  }
  if (currency === "") {
    newCurrency = "USDT";
  }
  const Reg = /^-?\d+\.0+$/; //判断小数点后是否都为0
  const RegIsTwoDecimal = /^\d+(?:\.\d{1,2})?$/; //判断是否有两位小数
  if (!Number(value)) return "0.00" + "  " + newCurrency;
  if (Reg.test(value)) {
    //小数点都是0保留两位小数
    value = (value * 1).toFixed(2);
  } else {
    if (["BTC", "ETH"].includes(newCurrency)) {
      // value = Math.floor(value * 1000000) / 1000000;
      value = new Decimal(value).toDecimalPlaces(6, Decimal.ROUND_DOWN);
    } else {
      value = new Decimal(value).toDecimalPlaces(2, Decimal.ROUND_DOWN);
    }
    if (RegIsTwoDecimal.test(value)) {
      value = new Decimal(value).toFixed(2);
    }
  }
  let num_parts = value.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".") + "  " + newCurrency;
}

export function floatFormatByCurreny(value, currency) {
  const prefixMap = {
    VND: "VND",
    CNY: "￥",
    USDT: "＄",
  };
  if (!value) {
    return prefixMap[currency]
      ? prefixMap[currency] + "0.00"
      : prefixMap["CNY"] + "0.00";
  }
  if (currency !== "VND") {
    value = (value * 1).toFixed(2);
  }
  let num_parts = value.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (currency === "VND") {
    return prefixMap["VND"] + num_parts[0];
  }
  if (currency === "USDT") {
    return prefixMap["USDT"] + num_parts.join(".");
  }
  return prefixMap["CNY"] + num_parts.join(".");
}

export function twoDig(value) {
  if (value >= 10) {
    return value;
  }
  return "0" + value;
}

export function getMainAccount(loginName) {
  if (loginName == null || loginName.trim() == "") {
    return "";
  }
  if (loginName.endsWith("usdt")) {
    return loginName.substring(0, loginName.length - 4);
  }
  return loginName;
}

export function toMonthAndDay(value) {
  if (value) {
    return value
      .substring(0, 10)
      .replace("-", "/")
      .replace("-", "/");
  }
  return value;
}

export function noError(vue, message) {
  // if (message.fail) {
  //     if (message.msg && message.msg.startsWith("100004^")) {
  //         api.logout();
  //         vue.$store.commit('reset', null);
  //         vue.$router.push({'name': 'start'});
  //         return false;
  //     } else {
  //         Toast({
  //             message: message.msg,
  //             position: 'middle',
  //             duration: 3000
  //         });
  //         return false;
  //     }
  // }
  return true;
}

export function structContract(contract) {
  if (contract && contract.content) {
    contract.content = JSON.parse(contract.content);
  }
  return contract;
}

export function queryAndStoreVerifyCode(isCallback) {
  let params = {
    requestId: store.state.verifyCodeRequestId,
    isCallback: isCallback,
  };
  api.getVerifyCodeImage(params, function(res) {
    let message = res.data;
    if (message.fail) {
      Message({
        message: this.$t("public.msg.error.verificationCode"),
      });
      return;
    }
    storeCommit("verifyCode", message.data);
  });
  api.preLogin();
}

EventBus.addEventListener("toRelogin", function() {
  queryAndStoreVerifyCode();
});

export function queryAndStoreProfessionCommission(
  vue,
  { loginName, ...params }
) {
  //查专代
  let that = vue;
  let params1 = {
    line: 1,
    loginName: loginName,
    ...params,
  };
  api.queryCurrentCommissionRecord(params1, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      message.data.agentCommission._current = 1;
      storeCommit("currentProfessionCommissionAndContribution", message.data);
      queryAndStoreProfessionCustomerDynamic(
        message.data.agentCommission,
        vue,
        loginName
      );
    }
  });
  let params2 = {
    line: 1,
    subline: 0,
    loginName: loginName,
  };
  api.queryCurrentContract(params2, function(res) {
    let message = res.data;
    storeCommit("currentProfessionContract", structContract(message.data));
  });
}

//佣金数据加入佣金方案货币类型改造增加此接口调用方法 避免多接口调用
export function queryCurrentCommissionRecord(
  vue,
  { commissionType, mutilCurrency, subline }
) {
  storeCommit("agentCommissionLoading", true);
  const that = vue;
  const params = {
    line: 0,
    subline,
    commissionType,
    mutilCurrency,
  };
  api.queryCurrentCommissionRecord(params, (res) => {
    storeCommit("agentCommissionLoading", false);
    if (!res.data) return;
    let message = res.data;
    if (noError(that, message)) {
      message.data.agentCommission._current = 1;
      subline === 1 // 方案二
        ? storeCommit("currentSublineCommissionAndContribution", message.data)
        : storeCommit("currentNormalCommissionAndContribution", message.data);
    }
  });
}

//占成方案
function queryAndStoreSublineCommission(
  vue,
  { loginName, commissionType, mutilCurrency }
) {
  let that = vue;
  let params = {
    subline: 1,
    line: 0,
    loginName: loginName || store.state.userInfo.loginName,
    commissionType: "0",
    mutilCurrency,
  };
  api.queryCurrentCommissionRecord(params, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      message.data.agentCommission._current = 1;
      storeCommit("currentSublineCommissionAndContribution", message.data);
      queryAndStoreSublineCustomerDynamic(
        message.data.agentCommission,
        vue,
        loginName
      );
    }
  });
  let params2 = {
    subline: 1,
    line: 0,
    loginName: loginName,
  };
  api.queryCurrentContract(params2, function(res) {
    let message = res.data;
    storeCommit("currentSublineContract", structContract(message.data));
  });
}

function queryAndStoreCustomerDynamic(currentNormalCommission, vue, loginName) {
  let that = vue;
  let params = {
    startDate: currentNormalCommission.startDate,
    endDate: currentNormalCommission.endDate,
    agentCommissionId: currentNormalCommission.agentCommissionId,
    commissionIssueId: currentNormalCommission.commissionIssueId,
    cycleType: currentNormalCommission.cycleType,
    line: currentNormalCommission.line,
    subline: currentNormalCommission.subline,
  };
  if (loginName) {
    params.loginName = loginName;
  }
  if (params.startDate == null) {
    return;
  }
  api.queryCommissionDynamic(params, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      storeCommit("customerDynamicInfo", message.data);
    }
  });
}

function queryAndStoreProfessionCustomerDynamic(
  currentProfessionCommission,
  vue,
  loginName
) {
  //查专代会员动态
  let that = vue;
  let params = {
    startDate: currentProfessionCommission.startDate,
    endDate: currentProfessionCommission.endDate,
    agentCommissionId: currentProfessionCommission.agentCommissionId,
    commissionIssueId: currentProfessionCommission.commissionIssueId,
    cycleType: currentProfessionCommission.cycleType,
    line: currentProfessionCommission.line,
    subline: currentProfessionCommission.subline,
  };
  if (loginName) {
    params.loginName = loginName;
  }
  if (params.startDate == null) {
    return;
  }
  api.queryCommissionDynamic(params, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      storeCommit("customerProfessionDynamicInfo", message.data);
    }
  });
}

function queryAndStoreSublineCustomerDynamic(
  currentSublineCommission,
  vue,
  loginName
) {
  let that = vue;
  let params = {
    startDate: currentSublineCommission.startDate,
    endDate: currentSublineCommission.endDate,
    agentCommissionId: currentSublineCommission.agentCommissionId,
    commissionIssueId: currentSublineCommission.commissionIssueId,
    cycleType: currentSublineCommission.cycleType,
    line: currentSublineCommission.line,
    subline: currentSublineCommission.subline,
  };
  if (loginName) {
    params.loginName = loginName;
  }
  if (params.startDate == null) {
    return;
  }
  api.queryCommissionDynamic(params, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      storeCommit("customerSublineDynamicInfo", message.data);
    }
  });
}

export function queryAndStoreCommonInfo(vue, { loginName } = {}) {
  let that = vue;
  return new Promise(async (resolve) => {
    await api.queryUserInfo((res) => {
      let message = res.data;
      if (noError(vue, message)) {
        let users = message.data;
        if (users.length > 0) {
          let userInfo = users[0];
          if (api.productInfo.prefixLoginName == "0") {
            // if (userInfo.loginName) {
            //     userInfo.loginName = userInfo.loginName.substring(1, userInfo.loginName.length);
            // }
          }
          storeCommit("userInfo", userInfo);
          storeCommit("isSubAccount", userInfo.currency === "USDT");

          // 把新的userInfo.customerId ，update到loginInfo
          api.loginInfo.userId = userInfo.customerId;
          api.saveLoginInfoToStorage();

          // 0普通代理，1总代，2专业代理
          let isGeneral = userInfo.lineType !== "0";
          storeCommit("isGeneral", isGeneral);
          if (isGeneral) {
            // 专业合作商佣金
            queryAndStoreProfessionCommission(vue, { loginName });
          }
          let isSubline = userInfo.sublineContractCode != null;
          storeCommit("isSubline", isSubline);
          if (isSubline) {
            //方案二
            queryAndStoreSublineCommission(vue, { loginName });
          }
        }
      }
    }, loginName);
    let params1 = {
      line: 0,
      subline: 0,
      loginName: loginName,
      mutilCurrency: store.state.currentWallet.currency,
    };
    storeCommit("currentNormalCommissionAndContribution", {
      agentCommission: {
        _fake: true,
        agentCommission: "0",
        firstCustNum: 0,
        startDate: "",
        endDate: "",
        subline: 0,
        line: 0,
      },
      oneLevelContribution: [],
      twoLevelContribution: [],
      threeLevelContribution: [],
    }); // 初始化一个数据
    storeCommit("agentCommissionLoading", true);
    await api.queryCurrentCommissionRecord(params1, (res) => {
      storeCommit("agentCommissionLoading", false);
      if (!res.data) return;
      let message = res.data;
      if (noError(that, message)) {
        message.data.agentCommission._current = 1;
        storeCommit("currentNormalCommissionAndContribution", message.data);
        queryAndStoreCustomerDynamic(
          message.data.agentCommission,
          vue,
          getMainAccount(loginName)
        );
      }
    });
    let params2 = {
      line: api.loginInfo.isInnerAgent === 4 ? 3 : 0,
      subline: 0,
      loginName: loginName,
      mutilCurrency: store.state.currentWallet.currency,
    };
    await api.queryCurrentContract(params2, function(res) {
      let message = res.data || {};
      storeCommit("currentNormalContract", structContract(message.data));
      resolve();
    });

    let params3 = {
      line: api.loginInfo.isInnerAgent === 4 ? 3 : 0,
      subline: 0,
      mutilCurrency: store.state.currentWallet.currency,
    };
    await api.queryMainContract(params3, function(res) {
      let message = res.data || {};
      storeCommit("mainNormalContract", structContract(message.data));
      resolve();
    });
  });
}

export function calIncomeCommission(agentCommissionRecord) {
  let result =
    agentCommissionRecord.agentCommission +
    agentCommissionRecord.lastAgentCommission;
  if (result < 0) {
    // 负数，总是不可入账
    return 0;
  }
  if (
    agentCommissionRecord.isStandard == 0 &&
    agentCommissionRecord.noStanCommiFlag == 3
  ) {
    // 不达标且不可入账
    return 0;
  }
  return floatFormat(result);
}

export function contractMinEffectiveCustNum(value) {
  if (value && value.content && value.content[0] && value.content[0].levels) {
    let levels = value.content[0].levels;
    for (let i = 0; i < levels.length; i++) {
      if (levels[i].level == 1) {
        return levels[i].minEffective;
      }
    }
  }
  return 0;
}

export function notincommission(vue, line) {
  let that = vue;
  let params = { line: line, flag: "0,1,2,5,6", currentPeriod: false };
  api.notincommission(params, function(res) {
    let message = res.data;
    if (noError(that, message)) {
      let notincommission = 0;
      for (let i = 0; i < message.data.content.length; i++) {
        notincommission =
          notincommission + message.data.content[i].agentCommission;
      }
      storeCommit("notincommission" + line, notincommission);
    }
  });
}

export function formatLoginName(loginName) {
  let isHide = api.productInfo.hide_login_name;
  if (api.productInfo.prefixLoginName == "0") {
    if (loginName) {
      loginName = loginName.substring(1, loginName.length);
    }
  }
  if ("1" === isHide) {
    if (api.loginInfo.show_downline_name == 0) {
      if (loginName) {
        let start = loginName.substring(0, loginName.length - 4);
        let end = loginName.substring(loginName.length - 1, loginName.length);
        return start + "***" + end;
      }
    }
  }
  return loginName;
}

export function routerPosition() {
  setTimeout(function() {
    //不这样会导致routerList 虽然已经变了但是页面是没有刷新的 active会有延迟
    let $liList = $(".historical-record>ul>li");
    let width = 0;
    for (let i = 0, len = $liList.length; i < len; i++) {
      width += $($liList[i]).outerWidth();
      if ($($liList[i]).hasClass("active")) {
        i = len + 1;
      }
    }
    let ulWidth = $(".historical-record>ul").outerWidth();
    let needWidth = 0;
    if (width > ulWidth) {
      let start = false;
      for (let j = $liList.length; j > 0; j--) {
        if ($($liList[j]).hasClass("active")) {
          start = true;
        }
        if (start && needWidth <= ulWidth) {
          needWidth += $($liList[j]).outerWidth();
          if (needWidth > ulWidth) {
            needWidth -= $($liList[j]).outerWidth();
            j = 0;
          }
        }
      }
      $(".historical-record>ul").css({ left: needWidth - width + "px" });
    } else {
      $(".historical-record>ul").css({ left: 0 });
    }
    let container = document.querySelectorAll(
      ".agent-content>.el-scrollbar>.el-scrollbar__wrap"
    )[0];
    container && (container.scrollTop = 0);
  }, 0);
}

export function dateFormat(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month < 10 && (month = "0" + month);
  let day = date.getDate();
  day < 10 && (day = "0" + day);
  return year + "-" + month + "-" + day;
}

export function arraySorting(arr) {
  let len = arr.length,
    temp;
  for (let i = 0; i < len - 1; i++) {
    for (let j = len - 1; j > i; j--) {
      if (arr[j].commission > arr[j - 1].commission) {
        temp = arr[j];
        arr[j] = arr[j - 1];
        arr[j - 1] = temp;
      }
    }
  }
  return arr;
}

export function checkApiLoginToken() {
  //确定初次进入或者刷新是页面所在路由
  if (!usePublic) {
    if (api.loginInfo.token && api.loginInfo.token !== "xxx") {
      //token不是初始值xxx 说明登录过再看登录是否还有效
      let params = {
        token: api.loginInfo.token,
      };
      api.isApiLoginTokenEffective(params, function(res) {
        //看登录是否还有效
        let message = res.data;
        if (!message.data) {
          //登录无效路由跳到登录页 初始化store
          api.logout();
          store.commit("reset", null);
          router.push({ name: "login" });
          queryAndStoreVerifyCode();
          Message({
            message: "登陆超时，请重新登陆",
            type: "error",
          });
        } else {
          store.commit("logined", true); //登录有效
        }
      });
    } else {
      router.push({ name: "login" }); //token 是初始值xxx 还未登录跳转到登录页
    }
  }
}

export function getAgentAccess() {
  api.getDynamicData({ bizCode: "AGENT_ACCESS_SWITCH" }).then((res) => {
    const { body = {} } = res.data;
    if (body.data && body.data.length) {
      const config = body.data[0];
      const isWebsiteAccess = !!+config.flag;
      storeCommit("isAccessModal", !isWebsiteAccess);
    }
  });
}

export function getWallet() {
  //获取多货币钱包
  api.queryWalletByLoginName({}, (res) => {
    const message = res.data;
    const resData = message.data || [];
    const options = resData.map((v) => {
      {
        return {
          ...v,
          label: v.currency,
          value: v.currency,
        };
      }
    });
    storeCommit("walletList", options);
    storeCommit("selestCurrency", "USDT");
    setTimeout(() => {
      const currency = store.state.currentWallet.currency || "USDT";
      const wallet = options.find((v) => v.currency === currency);
      storeCommit("currentWallet", wallet);
    }, 0);
  });
}
