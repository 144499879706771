<template>
  <div id="account-switch" v-if="showSwitch" :class="{'smell' : !inHomePage}">
    <span class="button usdt" :class="{ active: currency === 1 }" @click="switchCurrency(1)">USDT</span>
    <span class="button cny" :class="{ active: currency === 0 }" @click="switchCurrency(0)">人民币</span>
  </div>
</template>

<script>
import * as api from "@/api/api";
import * as myutil from "@/api/myutil";

export default {
  name: "accountSwitch",
  props: {
    inHomePage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showSwitch () {
      return (api.loginInfo.uiModeOptions && api.loginInfo.uiModeOptions.length >= 2)
    },
    currency() {
      return Number(this.$store.state.currency)
    }
  },
  created() {
    // console.log(loginInfo)
  },
  methods: {
    async switchCurrency(currency) {
      if (this.currency === currency) return;
      const loading = this.$loading({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)'
      });

      await api.switchAccount()

      await myutil.queryAndStoreCommonInfo(this);
      setTimeout(() => {
        window.location.reload();
      }, 500)

      loading.close();
    },
  }
}
</script>

<style lang="less" scoped>
#account-switch{
  color: rgba(255, 255, 255, .8);
  margin-top: 14px;
  text-align: center;
  font-size: 12px;
  .button {
    display: inline-block;
    width: 83px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    background: #5184e2;
  }
  .button.active {
    background: #135cdf;
    color: #fff;
  }
  .button.usdt {
    border-right: transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .button.cny {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.smell{
    width: 120px;
    margin: -20px 0 0 0;
    float: left;
    //background: rgba(255, 255, 255, .05);
    //color: rgba(255, 255, 255, .3);
    .button {
      width: 50px;
      //border: 1px solid rgba(255, 255, 255, .1);
    }
  }
}
</style>
