<template>
  <div>
    <el-menu
      ref="navMenu"
      :default-active="defaultActive"
      :router="router"
      :collapse-transition="router"
      text-color="#fff"
      class="agent-menu"
      :unique-opened="router"
    >
      <el-submenu
        v-for="item in filterMenus"
        v-show="
          (isGeneral || (!isGeneral && item.index !== '/proManagement1')) &&
          (!userInfo.webResource ||
            userInfo.webResource.indexOf(item.id) !== -1 ||
            item.id === 0)
        "
        :key="item.index"
        :index="item.index"
      >
        <template slot="title">
          <el-menu-item
            :index="item.index"
            :key="item.index"
            @click="menuClick(item.name, item.index)"
          >
            <i
              class="el-icon-wallet"
              v-if="item.icon == 'finance'"
              style="font-size: 22px"
            ></i>
            <i class="nav-icon" v-else :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </el-menu-item>
        </template>
        <el-menu-item
          v-for="value in item._child"
          v-show="!(productId === 'C07' && value.index === '/tools')"
          :key="value.index"
          v-bind:index="value.index"
          @click="menuClick(value.name, value.index)"
        >
          <i class="nav-icon" :class="value.icon"></i>
          <span>{{ value.name }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>

    <div class="logo">
      <img src="../../assets/img/svg/Logo_lite.svg" alt="" />
    </div>
  </div>
</template>

<script>
import * as myutil from "../../api/myutil.js";
import * as api from "../../api/api.js";
import { mapState } from "vuex";
export default {
  name: "myNav",
  data() {
    return {
      router: true,
      routerList: this.$store.state.routerList,
      isGeneral: this.$store.state.isGeneral,
      menu: [
        {
          name: this.$t("myNav.label.home"),
          icon: "home",
          index: "/home",
          id: 0, //office 未加
          _child: [],
        },
        // ,{
        //     name: this.$t('myNav.label.proManagement'),
        //     icon: 'pro',
        //     index: '/proManagement',
        //     id: 0,//office未加
        //     _child: [
        //     ]
        // }
        {
          name: this.$t("myNav.label.finance"),
          icon: "finance",
          index: "/finance",
          id: 2000,
          _child: [
            // {
            //     name: this.$t('home.label.currentCommission'),
            //     icon: '',
            //     index: '/currentCommission',
            //     id: 200002
            // },{
            //     name: this.$t('myNav.label.commissionProposal'),
            //     icon: '',
            //     index: '/commissionProposal',
            //     id: 200003
            // },
            {
              name: this.$t("myNav.label.commissionData"),
              icon: "",
              index: "/commissionHistory",
              id: 200003,
            },
            {
              name: this.$t("myNav.label.proManagement"),
              // icon: 'pro',
              index: "/proManagement",
              id: 0, //office未加
              // _child: [
              // ]
            },
            {
              name: this.$t("myNav.label.proCommissionData"),
              icon: "",
              index: "/proCommissionHistory",
              id: 200006,
            },
            // {
            //     name: this.$t('myNav.label.proCurrentCommission'),
            //     icon: '',
            //     index: '/proCurrentCommission',
            //     id: 200006
            // }, {
            //     name: this.$t('myNav.label.proCommissionProposal'),
            //     icon: '',
            //     index: '/proCommissionProposal',
            //     id: 200007
            // },
            {
              name: this.$t("myNav.label.proProportionProposal"),
              icon: "",
              index: "/proProportionProposal",
              id: 100006,
            },
            {
              name: this.$t("myNav.label.proProportionCurrentCommission"),
              icon: "",
              index: "/proProportionCurrentCommission",
              id: 100006,
            },
          ],
        },
        {
          name: this.$t("myNav.label.management"),
          icon: "member",
          index: "/management",
          id: 1000,
          _child: [
            // {
            //   name: this.$t("myNav.label.dynamic"),
            //   icon: "",
            //   index: "/dynamic",
            //   id: 100004,
            // },
            {
              name: this.$t("myNav.label.statistics"),
              icon: "",
              index: "/statistics",
              id: 100002,
            },
            {
              name: this.$t("myNav.label.information"),
              icon: "",
              index: "/information",
              id: 100001,
            },
            {
              name: this.$t("myNav.label.memberWinLose"),
              icon: "",
              index: "/profit",
              id: 100003,
            },
            {
              name: this.$t("myNav.label.proDownLine"),
              icon: "",
              index: "/proDownLine",
              id: 100005,
            },
          ],
        },
        {
          name: this.$t("myNav.label.promotion"),
          icon: "promotion",
          index: "/promotion",
          id: 3000,
          _child: [
            {
              name: this.$t("myNav.label.links"),
              icon: "",
              index: "/links",
              id: 300001,
            },
            {
              name: this.$t("myNav.label.material"),
              icon: "",
              index: "/material",
              id: 300002,
            },
            // { //推广小工具
            //   name: this.$t("myNav.label.tools"),
            //   icon: "",
            //   index: "/tools",
            //   id: 300003,
            // },
          ],
        },
        {
          name: this.$t("myNav.label.systemManagement"),
          icon: "last_agent_commission",
          index: "/commissionSetting",
          id: 4000,
          _child: [
            {
              name: this.$t("myNav.label.commissionSettings"),
              icon: "",
              index: "/commissionSetting",
              id: 400002,
            },
            // {
            //   name: this.$t("myNav.label.occupancySettings"),
            //   icon: "",
            //   index: "/occupancy",
            //   id: 400001,
            // },
          ],
        },
      ],
      isZongDai: false,
      defaultActive: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    productId() {
      return productId;
    },
    filterMenus() {
      const webResource = this.userInfo.webResource || "";
      this.menu.forEach((item) => {
        item._child = item._child.filter((v) => {
          if (
            (this.isGeneral ||
              (!this.isGeneral &&
                !["/proDownLine", "/proManagement1"].includes(v.index))) &&
            (!webResource || webResource.includes(v.id) || v.id === 0) &&
            (![100006, 200006, 200007].includes(v.id) &&
            !["/proManagement1"].includes(v.index))
          ) {
            return v;
          }
        });
      });
      return this.menu;
    },
  },
  watch: {
    $route(to, from) {
      sessionStorage.setItem("currentActiveIndex", to.path);
      if (to.path) {
        this.defaultActive = to.path;
      }
    },
  },
  mounted() {
    this.defaultActive = this.menu[0].index;
    const currentActiveIndex = sessionStorage.getItem("currentActiveIndex");
    if (currentActiveIndex) {
      this.defaultActive = currentActiveIndex;
    }

    if (!this.isGeneral) {
      this.menu[1]._child[1].index = "/proManagement1";
    }
    api.querySiteDirectLine({ customerId: this.userInfo.customerId }, (res) => {
      if (res.data.success) {
        this.isZongDai = res.data.data.zongDai;
      }
    });
  },
  methods: {
    menuClick(name, index) {
      if (name === this.$t("myNav.label.commissionSettings")) {
        return;
      }

      sessionStorage.setItem("currentActiveIndex", index);
      let isAdd = true;
      this.routerList.forEach((item) => {
        item.active = false;
        if (item.title === name) {
          isAdd = false;
          item.active = true;
        }
      });
      if (isAdd) {
        let obj = {
          title: name,
          name: index.slice(1),
          active: true,
        };
        this.routerList.push(obj);
        this.$store.commit("routerList", this.routerList);
      }
      myutil.routerPosition();
    },
  },
};
</script>

<style scoped lang="less">
.home {
  background: url("../../assets/img/icon_home.png") no-repeat;
}
.pro {
  background: url("../../assets/img/icon_pro.png") no-repeat;
}
.member {
  background: url("../../assets/img/icon_member.png") no-repeat;
}
.promotion {
  background: url("../../assets/img/icon_promotion.png") no-repeat;
}
.finance {
  background: url("../../assets/img/icon_finance.png") no-repeat;
}
.last_agent_commission {
  background: url("../../assets/img/gear.png") no-repeat;
}
.nav-icon {
  background-size: 20px 20px;
  transform: rotate(0deg);
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.logo {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  img {
    display: inline-block;
    height: 30px;
    width: 100%;
  }
}
</style>
