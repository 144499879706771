<template>
  <el-dialog
    :title="$t('app.label.messageTitle')"
    :visible.sync="visible"
    style="height: 100%"
  >
    <div class="message-table">
      <ul
        v-loading="loading"
        class="resize-height"
        :element-loading-text="$t('public.label.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)"
      >
        <li class="message-table-title">
          <span v-for="(item, index) in tableHeadList" :key="index">{{
            item.title
          }}</span>
        </li>
        <el-scrollbar
          style="height: calc(100% - 92px)"
          v-if="messageList.length > 0"
        >
          <li
            class="table-list"
            v-for="(item, index) in messageList"
            :key="index"
            :style="index % 2 === 0 ? { backgroundColor: '#f8f8f8' } : {}"
          >
            <span
              class="modify-msg"
              @click="modifyMsgDetail(item)"
              style="color: #3975e2"
              >{{ item.property1 }}</span
            >
            <span :class="item.flag === '0' ? 'color-g' : ''">{{
              item.flag === "0" ? $t("app.label.unread") : $t("app.label.read")
            }}</span>
            <span>{{ item.createdDate }}</span>
          </li>
        </el-scrollbar>
        <li class="pagination_footer" v-show="totalCount > 0">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            :total="totalCount"
            :current-page="pageNum"
            @current-change="currentChange"
            @prev-click="currentChange"
            @next-click="currentChange"
            @size-change="sizeChange"
          >
          </el-pagination>
        </li>
        <div class="noData" v-if="messageList.length === 0 && !loading">
          <i class="icon icon-no-record"></i>
          <div style="font-size: 20px; color: #aaa">
            {{ $t("public.label.noData") }}
          </div>
        </div>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import * as api from "@/api/api";
import { storeCommit } from '../../api/myutil';
import { mapState } from 'vuex';
export default {
  name: "MessageListDialog",
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      formMsgDetail: {},
      tableHeadList: [
        {
          title: this.$t("app.label.title"),
        },
        {
          title: this.$t("public.label.status"),
        },
        {
          title: this.$t("app.label.date"),
        },
      ],
      messageList: [],
      pageSize: 10,
      pageNum: 1,
      pageSizes: [10, 20, 50],
      totalCount: 0,
      loading: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.pageNum = 1;
    this.pageSize = 10;
    this.getMessageList();
  },
  methods: {
    updateUnreadMessage(){
      const arr = this.messageList.filter((v) => v.flag == 0);
      storeCommit("unreadMessage", arr);
    },
    modifyMsgDetail(item) {
      const params = {
        id: item.id,
        flag: 1,
      };

      api.modifyMsg(params, (res) => {
        let message = res.data;
        if (!message.fail) {
          item.flag = params.flag;
          this.updateUnreadMessage()
        }
      });
      this.formMsgDetail = { ...item };
      this.$emit("modifyMsgDetail", item);
    },
    sizeChange(value) {
      this.pageSize = value;
      this.pageNum = 1;
      this.getMessageList();
    },
    currentChange(value) {
      this.pageNum = value;
      this.getMessageList();
    },
    getMessageList() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      this.loading = true;
      api.queryMsg(params, (res) => {
        const message = res.data;
        const { content, totalCount } = message.data;
        this.loading = false;
        if (!message.fail) {
          this.messageList = content;
          this.updateUnreadMessage()
        }
        this.totalCount = totalCount;
      });
    },
  }
};
</script>
 