import Qs from 'qs';
import axios from 'axios';
import * as md5 from './md5';
import EventBus from 'eventbusjs';
import { Message } from 'element-ui';
import store from '../store';
import JSEncrypt from 'jsencrypt';
import publicMd5 from 'js-md5';
import * as myutil from '../api/myutil';

import router from '../router';
import {getMainAccount} from "../api/myutil";

let storage = sessionStorage;

export let loginInfo = {
  signature: 'xxx',
  appToken: 'xxx',
  ip: 'xxx',
  loginName: 'xxx',
  userId: -1,
  token: 'xxx',
  user_token: 'xxx',
  show_downline_name: 0,
  isMerChant: false,
  showToDownline: false,
  isHaveBaseContract: 0,
  mainAccountName: 'xxx',
  isInnerAgent:"xxx",
};

const loginErrCode = ['GW_890206', 'GW_880103', 'GW_890203', 'GW_899998', 'GW_890209', 'GW_890406']

export let acceptTypes = {};
let acceptTypesFromStorage = storage.getItem('acceptTypes');
if (acceptTypesFromStorage) {
  try {
    acceptTypes = JSON.parse(acceptTypesFromStorage);
  } catch (e) {
    // 当接口返回值出错时，会导致解析错误
  }
}

export let productInfo = {
  productId: productId
};
export let platform = {}
let platformFromStorage = storage.getItem('platform');
if (platformFromStorage) {
  try {
    platform = JSON.parse(platformFromStorage);
  } catch (e) {
    // 当接口返回值出错时，会导致解析错误
  }
}
export let platformOptions = [];
let loginInfoFromStorage = storage.getItem('loginInfo');
if (loginInfoFromStorage) {
  try {
    loginInfo = JSON.parse(loginInfoFromStorage);
  } catch (e) {
    // 当接口返回值出错时，会导致解析错误
  }
}

let webToken = {};
// let webTokenStorage = sessionStorage.getItem('webToken');
// if (webTokenStorage) {
//   try {
//     webToken = JSON.parse(webTokenStorage);
//   } catch (e) {
//     // 当接口返回值出错时，会导致解析错误
//   }
// }

// ======================
function change_favicon(img) {
  let favicon = document.querySelector('link[rel="shortcut icon"]');

  if (!favicon) {
    favicon = document.createElement('link');
    favicon.setAttribute('rel', 'shortcut icon');
    let head = document.querySelector('head');
    head.appendChild(favicon);
  }
  favicon.setAttribute('type', 'image/png');
  favicon.setAttribute('href', img);
}

export function updateCauseProductInfo(productInfo) {
  document.title = productInfo.systemTitle;
  // document.title="J9.COM-首家基于Web3.0的加密货币赌场"
  if (productInfo.logo) {
    //change_favicon("./static" + productInfo.logo.trim());//本地
    change_favicon("." + productInfo.logo.trim());//打包
  }
}

let productInfoFromStorage = storage.getItem('productInfo');
if (productInfoFromStorage) {
  try {
    productInfo = JSON.parse(productInfoFromStorage);
    updateCauseProductInfo(productInfo);
  } catch (e) {
    // 当接口返回值出错时，会导致解析错误
  }
}

export function saveLoginInfoToStorage() {
  storage.setItem('loginInfo', JSON.stringify(loginInfo));
}

export function saveProductInfoToStorage() {//1
  storage.setItem('productInfo', JSON.stringify(productInfo));
}

// 2-agent-mobile
const requestSource = '3';
const timeout = 60000;
let timestamp = new Date().getTime();

// 创建axios实例
const requestGateway = axios.create({
  baseURL: gatewayBaseUrl,
  timeout: timeout,
  // withCredentials: true
});

const requestAgentApi = axios.create({
  baseURL: agentApiBaseUrl,
  timeout: timeout,
  // withCredentials: true
});

const requestPublicGateway = axios.create({
  baseURL: publicGatewayBaseUrl,
  timeout: timeout
});

// ==============================
function genSignature() {
  return md5.hex_md5('app_id=' + app_id + '&platform=wap&timestamp=' + timestamp + '&app_key=' + app_key);
}

function randomFn(length = 32) {
  let text = []
  let pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    text.push(pool.charAt(Math.floor(Math.random() * pool.length)))
  }

  return text.join('')
}

function sortString(str) {
  return str.split('').sort().reverse().join('');
}

const base64DecodeChars = new Array(
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
    -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
    -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1);

export function base64decode(str) {
  var c1, c2, c3, c4;
  var i, len, out;

  len = str.length;
  i = 0;
  out = "";
  while (i < len) {
    /* c1 */
    do {
      c1 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (i < len && c1 == -1);
    if (c1 == -1)
      break;

    /* c2 */
    do {
      c2 = base64DecodeChars[str.charCodeAt(i++) & 0xff];
    } while (i < len && c2 == -1);
    if (c2 == -1)
      break;

    out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));

    /* c3 */
    do {
      c3 = str.charCodeAt(i++) & 0xff;
      if (c3 == 61)
        return out;
      c3 = base64DecodeChars[c3];
    } while (i < len && c3 == -1);
    if (c3 == -1)
      break;

    out += String.fromCharCode(((c2 & 0XF) << 4) | ((c3 & 0x3C) >> 2));

    /* c4 */
    do {
      c4 = str.charCodeAt(i++) & 0xff;
      if (c4 == 61)
        return out;
      c4 = base64DecodeChars[c4];
    } while (i < len && c4 == -1);
    if (c4 == -1)
      break;
    out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
  }
  return out;
}

export function requestAppTokenImpl(cb) {
  loginInfo.signature = genSignature();
  saveLoginInfoToStorage();
  requestGateway({
    url: '/auths/appToken?isCross=1',
    data: Qs.stringify({
      app_id: app_id,
      platform: 'wap',
      timestamp: timestamp,
      signature: loginInfo.signature,
      isCross: 1
    }),
    headers: {
      'ip-address': '127.0.0.1',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      // 'HTTP_USER_AGENT':'great-winner',
      // 'Access-Control-Request-Method': 'PUT,POST,OPTIONS',
      // 'Access-Control-Request-Headers': 'Origin,X-PINGOTHER, Content-Type, accept',
    },
    method: 'post',
  }).then(function (res) {
    let message = res.data;
    if (message.status == false) {
      // Toast({
      //     message: res.data.message,
      //     position: 'middle',
      //     duration: 3000
      // });
    }

    loginInfo.appToken = message.data.appToken;
    loginInfo.ip = message.data.ip;
    saveLoginInfoToStorage();
    if (cb) {
      cb();
    }
  }).catch(function (error) {
    if (error.message.search("timeout") != -1) {
      // Toast({
      //     message: this.$t('public.msg.error.networkTimeout'),
      //     position: 'middle',
      //     duration: 3000
      // });
    } else if (error.message.search("404") != -1) {
      // Toast({
      //     message: this.$t('public.msg.error.requestedAddress'),
      //     position: 'middle',
      //     duration: 3000
      // });
    }

  })
}

function publicToken(cb) {
  let qid = randomFn();
  let params = {
    'productId': productId
  };
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version);
  requestPublicGateway({
    url: '/webToken',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
    },
    method: 'post',
  }).then(function (res) {
    if (res && res.data.head.errCode === '0000') {
      let webToken = base64decode(res.data.body.info);
      sessionStorage.setItem('webToken', webToken);
      if (cb) {
        cb();
      }
    } else {

    }
  }).catch(function (error) {
    if (error.message.search("timeout") != -1) {
      Message({
        message: "网络连接超时",
        type: 'error'
      });
    } else if (error.message.search("404") != -1) {
      Message({
        message: "找不到对应的请求地址,请检查!",
        type: 'error'
      });
    }
  });
}

export function requestAppToken(cb) {
  // if (!useAgentApi) {
  if (usePublic) {
    if (location.href.indexOf('?') === -1 && !webToken.deviceId) {
      publicToken(cb);
    } else {
      if (cb) {
        cb();
      }
    }
  } else {
    requestAppTokenImpl(cb);
  }
  // } else {
  //   cb();
  // }
}

// ============================

function genCommonCb(cb) {
  // if (_dealSelf) {
  //     return cb;
  // }
  return function (res) {
    let message = res.data;
    if (message.fail) {//_dealSelf 为false 且接口返回 fail = false 时才进来
      if (message.msg.indexOf('设置为不计算') !== -1) {
        return
      }
      if (message.msg && message.msg.indexOf('^')) {
        Message({
          message: message.msg.slice(message.msg.lastIndexOf('^') + 1),
          type: 'error'
        });
        if (message.msg.startsWith("100004^") || message.msg.startsWith("100005^") || message.msg.indexOf('访问凭证已失效') !== -1) {
          logout();
          store.commit('reset', null);
          EventBus.dispatch("toRelogin");
        }
      } else {
        Message({
          message: message.msg ? message.msg : '未知错误请重试',
          type: 'error'
        });
      }
    } else {//_dealSelf 为false 且接口返回 fail = true 时才进来
      if (cb) {
        cb(res);
      }
    }
  };
}

function fetch(opt, cb) {
  let _dealSelf = opt.data._dealSelf;
  let request = {
    para: opt.data,
    productId:productId,
    userId: loginInfo.userId,
    userName: loginInfo.loginName,
    userType: 'CUSTUSER',
    token: loginInfo.token,
    requestSource: requestSource,
    language: 'zh'
  };
  if (opt.url !== '/customer/customerLogin.do' && opt.url !== "/productConstant/queryForAgentMobile.do" && opt.url !== '/announcements/queryAnnouncements.do' && opt.url !== '/customer/getVerifyCodeImage.do' && loginInfo.loginName === 'xxx') {
    return
  }
  if (!store.state.logined) {
    router.push({ name: 'login' })
  }

  // if (useAgentApi) {
  //   request.agentApiKey = agentApiKey;
  //   opt.data = request;
  //   opt.headers = {
  //     'Content-Type': 'application/json; charset=UTF-8',
  //     // 'HTTP_USER_AGENT':'great-winner',
  //     // 'Access-Control-Request-Method': 'PUT,POST,OPTIONS',
  //     // 'Access-Control-Request-Headers': 'Origin,X-PINGOTHER, Content-Type, accept',
  //     // 'ip-address': '127.0.0.1',
  //     // 'referer': 'test.com',
  //     // 'hosts': 'test.com'
  //   };
  // } else {
  if (usePublic) {
    if (!sessionStorage.getItem('webToken')) return logout();

    let webTokenStorage = sessionStorage.getItem('webToken');
    if (webTokenStorage) {
      try {
        webToken = JSON.parse(webTokenStorage);
      } catch (e) {

      }
    }
    let keyArr = Object.keys(opt.data);
    let data = null;
    if (opt.url !== "/customer/saveCustProportion.do") {
      data = {};
      keyArr.forEach(function (key) {
        data[key] = opt.data[key];
      });
    } else {
      data = opt.data;
    }
    let qid = randomFn();
    request.url = opt.url;
    opt.data = {};
    opt.data.productId = productId;
    opt.data.deviceId = webToken.deviceId;
    opt.data.uri = request.url;
    opt.data.para = data;
    opt.data.userId = loginInfo.userId;
    opt.data.requestSource = requestSource;
    opt.data.userName = loginInfo.loginName;
    opt.data.loginName = loginInfo.loginName;
    opt.data.language = 'zh'
    if (request.url === "/customer/findCustomersByLoginNames.do") {
      opt.data.para = loginInfo.loginName;
    }
    if (opt.data.uri === "/productConstant/queryForAgentMobile.do" || opt.data.uri === '/announcements/queryAnnouncements.do') {
      opt.url = '/agent/proxy/freeLogin'
    } else {
      opt.url = '/agent/proxy';
    }
    let sign = publicMd5(sortString(JSON.stringify(opt.data)) + qid + appId + version + webToken.token + webToken.u2token);
    opt.headers = {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token,
      'deviceId':webToken.deviceId
    };
  } else {
    request.url = opt.url;
    opt.url = '/agent/transfer?isCross=1';
    opt.data = Qs.stringify({
      agentPara: JSON.stringify(request),
      app_token: loginInfo.appToken
    });
    opt.headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    };
  }
  // }
  let sender = null;
  // if (useAgentApi) {
  //   sender = requestAgentApi(opt);
  // } else {
  if (usePublic) {
    sender = requestPublicGateway(opt);
  } else {
    sender = requestGateway(opt);
  }
  // }

  sender.then(function (res) {
    if (usePublic) {
      res.data.success = res.data.head.errCode === '0000';
      res.data.fail = res.data.head.errCode !== '0000';
      res.data.data = res.data.body;
      res.data.msg = res.data.head.errMsg;
      if (loginErrCode.includes(res.data.head.errCode)) {
        Message('登录过期，请重新登录！')
        logout();
        myutil.queryAndStoreVerifyCode();
        store.commit('reset', null);
        EventBus.dispatch("toRelogin");
        return
      }
    }
    if (res.data.message == 'app_token 无效') {
      timestamp = new Date().getTime();
      requestAppTokenImpl(null);
      sender = requestGateway(opt);
      if (!cb) {
        return;
      }
      if (_dealSelf) {
        sender.then(cb);
      } else {
        sender.then(genCommonCb(cb));
      }
    } else if (_dealSelf) {
      if (cb) {
        cb(res);
      }
    } else {
      genCommonCb(cb)(res);
    }
  }).catch(function (error) {
    console.log(error);
  });
}

// ==============================
// 所有的网络请求，都写在这里

export function isApiLoginTokenEffective(params, cb) {
  params.productId =productId;
  params._dealSelf = true;
  return fetch({
    url: '/customer/isApiLoginTokenEffective.do',
    method: 'post',
    data: params
  }, cb);
}

// 登录
export function loginAgent(params, cb) {
  // let pwd = params.pwd;
  if (!params.token) {
    params.productId =productId;
    params.pwd = md5.hex_md5(params.pwd + "{" + params.loginName + "}");
    params.ip = '127.0.0.1';
    params.domainName = window.location.host;
    // let newPwd = pwd.substr(0,pwd.length -2);
    // params.pwdPrefix = pwd.substr(pwd.length -2,pwd.length);
    // params.newPwd = md5.hex_md5(newPwd + "{" + params.loginName + "}");
  }
  return fetch({
    url: params.token ? '/customer/customerLoginByToken.do' : '/customer/customerLogin.do',
    method: 'post',
    data: params
  }, function (res) {
    
    let message = res.data;
    if (!message.fail) {
      loginInfo.token = message.data.token;
      loginInfo.isMerChant = message.data.biMerchant;
      loginInfo.showToDownline = message.data.showToDownline;
      loginInfo.show_downline_name = message.data.showDownlineName;
      // loginInfo.loginName = params.loginName; // 为了正确取得登入的是主或子账号，这边不要再复写了
      loginInfo.userId = message.data.customerId;
      loginInfo.isHaveBaseContract = message.data.isHaveBaseContract
      loginInfo.isInnerAgent = message.data.isInnerAgent
      acceptTypes = message.data.AcceptTypes;
      storage.setItem('acceptTypes', JSON.stringify(acceptTypes));
      storage.setItem('platform', JSON.stringify(platform));
      saveLoginInfoToStorage();
    }
    cb(res);
  });
}

function genLoginSignature(login_name, password) {
  return md5.hex_md5('app_token=' + loginInfo.appToken + '&login_name=' + login_name + '&password=' + password + '&timestamp=' + timestamp + '&app_key=' + app_key);
}

function loginGateway(params, cb) {
  let signature = genLoginSignature(params.loginName, params.pwd);
  //
  requestGateway({
    url: '/users/login?isCross=1',
    data: Qs.stringify({
      app_token: loginInfo.appToken,
      login_name: params.loginName,
      password: params.pwd,
      timestamp: timestamp,
      signature: signature,
      isCross: 1
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      // 'HTTP_USER_AGENT':'great-winner',
      // 'Access-Control-Request-Method': 'PUT,POST,OPTIONS',
      // 'Access-Control-Request-Headers': 'Origin,X-PINGOTHER, Content-Type, accept',
    },
    method: 'post',
  }).then(cb);
}

function loginPublicGateway(params, cb) {
  if (!webToken.deviceId) {
    let webTokenStorage = sessionStorage.getItem('webToken');
    if (webTokenStorage) {
      try {
        webToken = JSON.parse(webTokenStorage);
      } catch (e) {

      }
    } else {
      publicToken();
    }
  }
  let qid = randomFn();
  let encryptor = new JSEncrypt();  // 新建JSEncrypt对象
  encryptor.setPublicKey(webkey); // 设置公钥
  console.log('login productId',productId)
  let data = {
    password: encryptor.encrypt(params.pwd),
    loginName: params.loginName,
    productId:  productId,
    captchaId: params.captchaId,
    captcha: params.verificationCode
  };
  let sign = publicMd5(sortString(JSON.stringify(data)) + qid + appId + version + webToken.token + webToken.u2token);
  requestPublicGateway({
    url: '/customer/login',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token,
      'deviceId':webToken.deviceId
    },
    method: 'post',
  }).then(cb);
}

export function login(params, cb) {
  if (productInfo.prefixLoginName == '0' || productId === 'C07') {
    params.loginName = productInfo.prefixOfCustomer + params.loginName
  }
  // if (useAgentApi) {
  //   loginAgent(params, cb);
  // } else {
  if (usePublic) {
    loginPublicGateway(params, function (res) {
      if (res.data.head.errCode === '0000') {
        loginInfo.loginName = res.data.body.loginName;
        loginInfo.uiMode = res.data.body.uiMode;
        loginInfo.uiModeOptions = res.data.body.uiModeOptions;
        loginInfo.mainAccountName = res.data.body.mainAccountName;

        saveLoginInfoToStorage();
        store.commit('currency', Number(res.data.body.uiMode === 'USDT'));
        loginAgent(params, cb);
      } else {
        if (cb) {
          let message = res.data;
          message.msg = message.head.errMsg;
          message.showCode = message.head.errCode === 'GW_800101' || message.head.errCode === 'GW_800102';
          message.fail = true;
          cb(res);
        }
      }
    });
  } else {
    loginGateway(params, function (res) {
      let message = res.data;
      if (res.data.status) {
        loginInfo.user_token = message.data.WSCustomers.user_token;
        saveLoginInfoToStorage();
        store.commit('currency', Number(res.data.body.uiMode === 'USDT'));
        loginAgent(params, cb);
      } else {
        if (cb) {
          let message = res.data;
          message.msg = message.message;
          message.fail = true;
          cb(res);
        }
      }
    });
  }
  // }
}

// ==========================================
export function logout() {
  sessionStorage.removeItem('loginName');
  if (usePublic) {
    let qid = randomFn();
    let data = {
      loginName: loginInfo.loginName,
      productId: productId
    };
    let sign = publicMd5(sortString(JSON.stringify(data)) + qid + appId + version + webToken.token + webToken.u2token);
    requestPublicGateway({
      url: '/customer/logoutV2',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'AppId': appId,
        'Qid': qid,
        'v': version,
        'Sign': sign,
        'token': webToken.token,
        'deviceId':webToken.deviceId
      },
      method: 'post',
    }).then(function (res) {
      if (res.data.head.errCode === '0000') {
        store.commit('reset', null);
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
    });
  } else {
    let params = {
      productId:productId,
    };
    fetch({
      url: '/customer/customerLogout.do',
      method: 'post',
      data: params
    });
    store.commit('reset', null);
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  }
}

export function queryCurrentCommissionRecord(params, cb) {
  params.productId =productId;
  params.loginName = myutil.getMainAccount(loginInfo.loginName);
  params.checkContribution = 1;
  if (typeof (params.subline) == 'undefined') {
    params.subline = 0;
  }
  let userInfo = (typeof store.state.userInfo) === 'string' ? JSON.parse(store.state.userInfo) : store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  if ((params.subline === 0 && params.line === 0 && loginInfo.isHaveBaseContract == 0) || (params.subline === 1 && params.line === 0 && !userInfo.sublineContractCode)) {
    cb({})
    return
  }
  return fetch({
    url: '/commission/queryAgentCurrentCommission.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryAgentCommissionRecord(params, cb) {
  params.productId =productId;
  params.loginName = myutil.getMainAccount(loginInfo.loginName);
  params.isHiddenForUser = 0;
  let userInfo = (typeof store.state.userInfo) === 'string' ? JSON.parse(store.state.userInfo) : store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  return fetch({
    url: '/commission/queryAgentCommissionRecords.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryDownlineContribution(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/commission/queryDownlineContribution.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCurrentContract(params, cb) {
  params.productId =productId;
  params.loginName = params.loginName ? params.loginName : loginInfo.loginName;
  let userInfo =  store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  // params.subline = 0;
  return fetch({
    url: '/config/findAgentContractByLoginNameAndLine.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryMainContract(params, cb) {
  params.productId =productId;
  params.loginName = myutil.getMainAccount(loginInfo.loginName);
  params.subline = 0;
  params.line = 0;
  const userInfo =  store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  return fetch({
    url: '/config/findAgentContractByLoginNameAndLine.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryContract(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/config/queryAgentContracts.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryEffectiveCust(params, cb) {
  params.productId =productId;
  params.agentLevel = 1;
  params.isEffectiveCustomer = 1;
  return fetch({
    url: '/commission/queryDownlineContribution.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryDownlineCurrentContribution(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/commission/queryDownlineCurrentContribution.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomerLostWin(params, cb) {
  let times = new Date(params.end) - new Date(params.start);
  if (times / 1000 / 60 / 60 / 24 <= 31) {
    params.productId =productId;
    return fetch({
      url: '/lostWin/queryLostWin.do',
      method: 'post',
      data: params
    }, cb);
  } else {
    Message({
      type: 'error',
      message: '查询时间段不能超过一个月，请重新选择时间！'
    });
  }
}

export function queryCustomerAllLostWin(params, cb) {
  let times = new Date(params.end) - new Date(params.start);
  if (times / 1000 / 60 / 60 / 24 <= 31) {
    params.productId =productId;
    return fetch({
      url: '/lostWin/queryAllLostWin.do',
      method: 'post',
      data: params
    }, cb);
  } else {
    Message({
      type: 'error',
      message: '查询时间段不能超过一个月，请重新选择时间！'
    });
  }
}

export function memberList(params, cb) {
  if (typeof params.createdDateBegin != 'undefined' && params.createdDateBegin != '') {
    params.createdDateBegin = params.createdDateBegin + " 00:00:00";
  }
  if (typeof params.createdDateEnd != 'undefined' && params.createdDateEnd != '') {
    params.createdDateEnd = params.createdDateEnd + " 23:59:59";
  }
  params.productId =productId;
  return fetch({
    url: '/customer/queryAgentCustomers.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryMemberList(params, cb) {
  if (typeof params.createdDateBegin != 'undefined' && params.createdDateBegin != '') {
    params.createdDateBegin = params.createdDateBegin + " 00:00:00";
  }
  if (typeof params.createdDateEnd != 'undefined' && params.createdDateEnd != '') {
    params.createdDateEnd = params.createdDateEnd + " 23:59:59";
  }
  params.excludeArbit = true;
  params.productId =productId;
  return fetch({
    url: '/customer/queryDownInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function addMember(params, cb) {
  params.loginName = productInfo.prefixOfCustomer + params.loginName;
  params.productId =productId;
  // params.sex == 1 ? params.sex = "M" : params.sex = "F";
  params.pwd = md5.hex_md5(params.pwd + "{" + params.loginName + "}");
  params.domainName = window.location.host;
  params.ipAddress = '127.0.0.1';
  params._dealSelf = true;
  return fetch({
    url: '/customer/addCustomers.do',
    method: 'post',
    data: params
  }, cb);
}

//  export function getBankList(params, cb) {
//     return fetch({
//         url: '/customer/queryCustomerBanksByLoginName.do',
//         method: 'post',
//         data: params
//     }, cb);
// }

// 获取取款账户接口（新）
export function getBankListNew(params, cb) {
  return fetch({
    url: '/customer/multiCurrencyWithdrawBankInfoGet.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryUserInfo(cb, loginName) {
  const params = {
    inclLockBalanceInfoFlag: 1,
    loginName : loginInfo.loginName
  }
  return fetch({
    url: '/customer/findCustomersByLoginNames.do',
    method: 'post',
    data: params
  }, cb);
}

export function createAgentExchange(params, cb) {
  params.priorityType = 0;
  params.ipAddress = '127.0.0.1';
  params.userType = 'C';
  params.endPointType = '2';//1-Office 2-PC 3-App
  // params.currency='CNY';
  let url = '/customer/createExchangeRequests.do';
  if (params.exchangeType === 1 || params.exchangeType === 3 || params.exchangeType === 5) {
    url = '/customer/createAndApproveExchangeRequests.do';
  }
  return fetch({
    url: url,
    method: 'post',
    data: params
  }, cb);
}

export function getBankInfo(params, cb) {
  return fetch({
    url: '/customer/queryBankInfoByCustomerBankId.do',
    method: 'post',
    data: params.customerBankId
  }, cb);
}

export function queryAnnouncements(cb) {
  let params = {
    productId:productId
  };
  return fetch({
    url: '/announcements/queryAnnouncements.do',
    method: 'post',
    data: params
  }, cb);
}

export function countAllDownline(params, cb) {
  params.productId =productId;
  if (params.regDateStart != '' && params.regDateStart != null) {
    params.regDateStart = params.regDateStart + " 00:00:00";
  }
  if (params.regDateEnd != '' && params.regDateEnd != null) {
    params.regDateEnd = params.regDateEnd + " 23:59:59";
  }
  params.excludeArbit = true;
  return fetch({
    url: '/customer/countDownInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomerDynamic(params, cb) {
  params.productId =productId;
  params.loginName = loginInfo.loginName;
  if (!params.customerId) {
    params.customerId = store.state.userInfo.customerId;
  }
  let userInfo = (typeof store.state.userInfo) === 'string' ? JSON.parse(store.state.userInfo) : store.state.userInfo
  if (store.state.isGeneral) {
    params.subline = 0;
    params.line = 1
  } else {
    if (loginInfo.isHaveBaseContract == 1) {
      params.line = 0
      params.subline = 0
    } else if (userInfo.sublineContractCode) {
      params.line = 0
      params.subline = 1
    } else {
      return
    }
  }
  return fetch({
    url: '/customer/queryCustomersDynamicNum.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCommissionDynamic(params, cb) {
  params.productId =productId;
  params.loginName = myutil.getMainAccount(loginInfo.loginName);
  params.customerId = store.state.userInfo.customerId;
  const userInfo =  store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  /*params.loginName = params.loginName ? params.loginName : loginInfo.loginName;
  if (!params.customerId) {
    params.customerId = store.state.userInfo.customerId;
  }*/
  return fetch({
    url: '/customer/queryCommissionDynamic.do',//queryCustomersDynamicNum.do
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomersDynamicList(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/findCustomersDynamicList.do',
    method: 'post',
    data: params
  }, cb)
}

export function queryExtendsLink(cb) {
  let params = {
    productId:productId,
    loginName: loginInfo.loginName
  };
  return fetch({
    url: '/extends/queryExtendsLink.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryExtendsMaterial(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/material/queryMaterialInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function hiddenCreditLogs(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  params.terminal = "MOBILE";
  return fetch({
    url: "/customer/hiddenCreditLogs.do",
    method: 'post',
    data: params
  }, cb);
}

export function queryAgentCreditLog(params, cb) {
  params.productId =productId;
  params.isHiddenForUser = 0;
  return fetch({
    url: '/customer/queryCreditLogs.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryProductConstant(cb) {
  let params = {
    productId:productId
  };
  return fetch({
    url: '/productConstant/queryForAgentMobile.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryDownline(params, cb) {
  params.productId =productId;
  params.agentLevel = 1;
  return fetch({
    // url: (params.downName && params.isMerChant) ? '/customer/queryDownInfoByName.do' : '/customer/queryDownInfo.do',
    // url: (params.downName && params.isMerChant) ? '/customer/queryDownInfoByName.do' : '/webApi/customer/queryDownInfo.do',
    // url: '/webApi/customer/queryDownInfo.do',
    url: (params.isMerChant) ? '/webApi/customer/3MqueryDownInfo.do' : '/webApi/customer/queryDownInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function getVerifyCodeImage(params, cb) {
  // if (usePublic && !params.isCallback) {
  if (!params.isCallback) {
    let qid = randomFn();
    let data = {
      productId: productId
    };
    let sign = publicMd5(sortString(JSON.stringify(data)) + qid + appId + version + webToken.token + webToken.u2token);
    requestPublicGateway({
      url: '/captcha/generate',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'AppId': appId,
        'Qid': qid,
        'v': version,
        'Sign': sign,
        'token': webToken.token
      },
      method: 'post',
    }).then(function (res) {
      res.data.success = res.data.head.errCode === '0000';
      res.data.fail = res.data.head.errCode !== '0000';
      res.data.data = res.data.body;
      res.data.msg = res.data.head.errMsg;
      cb(res)
    });
  }
  // } else {
  //   return fetch({
  //     url: '/customer/getVerifyCodeImage.do',
  //     method: 'post',
  //     data: params
  //   }, cb);
  // }
}

export function notincommission(params, cb) {
  params.isHiddenForUser = 0;
  params.productId =productId;
  return fetch({
    url: '/commission/queryOrCalculateAgentCommissionRecords.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryNoLoginList(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/queryNoLoginList.do',
    method: 'post',
    data: params
  }, cb);
}


export function queryCustomerDynamicInfo(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/queryCustomerDynamicInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomerDetail(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/queryCustomerDetail.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryRegisterFromInfo(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  if (!params.customerId) {
    params.customerId = loginInfo.userId;
  }
  return fetch({
    url: '/customer/queryRegisterFromInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryRegisterFromInfoByDate(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  if (!params.customerId) {
    params.customerId = loginInfo.userId;
  }
  return fetch({
    url: '/customer/queryRegisterFromInfoByDate.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomerOpenInfo(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  if (!params.customerId) {
    params.customerId = loginInfo.userId;
  }
  return fetch({
    url: '/customer/queryCustomerOpenInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function findAgentExchangeRequests(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/findAgentExchangeRequests.do',
    method: 'post',
    data: params
  }, cb);
}

export function findAgentContractByLoginName(params, cb) {
  params.productId =productId;
  const userInfo =  store.state.userInfo
  if(userInfo.isInnerAgent==4){ // 如果是特级代理line =3
    params.line = 3
  }
  return fetch({
    url: '/config/findAgentContractByLoginNameAndLine.do',
    method: 'post',
    data: params
  }, cb);
}

export function customerStatistics(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/commission/statisticsCustomerStatistics.do',
    method: 'post',
    data: params
  }, cb);
}

export function queryCustomerStatistics(params, cb) {
  let times = new Date(params.queryDateEnd) - new Date(params.queryDateBegin);
  if (times / 1000 / 60 / 60 / 24 <= 31) {
    params.productId =productId;
    return fetch({
      url: '/commission/queryNewCustomerStatistics.do',
      method: 'post',
      data: params
    }, cb);
  } else {
    Message({
      type: 'error',
      message: '查询时间段不能超过一个月，请重新选择时间！'
    });
  }
}

export function queryAllCustomerStatistics(params, cb) {
  let times = new Date(params.queryDateEnd) - new Date(params.queryDateBegin);
  if (times / 1000 / 60 / 60 / 24 <= 31) {
    params.productId =productId;
    return fetch({
      url: '/commission/queryAllCustomerStatistics.do',
      method: 'post',
      data: params
    }, cb);
  } else {
    Message({
      type: 'error',
      message: '查询时间段不能超过一个月，请重新选择时间！'
    });
  }
}

export function countAllTypeDownline(params, cb) {
  params.productId =productId;
  if (params.createdDateBegin != '' && params.createdDateBegin != null) {
    params.createdDateBegin = params.createdDateBegin + " 00:00:00";
  }
  if (params.createdDateEnd != '' && params.createdDateEnd != null) {
    params.createdDateEnd = params.createdDateEnd + " 23:59:59";
  }
  return fetch({
    url: '/customer/countAllTypeDownline.do',
    method: 'post',
    data: params
  }, cb);
}

export function querySumCreditLog(params, cb) {
  params.productId =productId;
  params.loginName = loginInfo.loginName;
  params.isHiddenForUser = 0;
  return fetch({
    url: '/customer/querySumCreditLog.do',
    method: 'post',
    data: params
  }, cb);
}

export function checkLoginName(params, cb) {
  params.productId =productId;
  return fetch({
    url: '/customer/checkCustomerInfo.do',
    method: 'post',
    data: params
  }, cb);
}

export function verifyLogin(params, cb) {
  params.productId =productId;
  params.pwd = md5.hex_md5(params.pwd + "{" + params.loginName + "}");
  params.domainName = window.location.host;
  params._dealSelf = true;

  return fetch({
    url: '/customer/verifyLogin.do',
    method: 'post',
    data: params
  }, function (res) {
    //这里不需要做什么 此时res.data === null 没有返回token 不要改变token值，否则会失去登录效果
    // let message = res.data;
    // if (!message.fail) {
    //     loginInfo.token = message.data;
    //     loginInfo.loginName = params.loginName;
    // }
    cb(res);
  });
}

export function validateTransactionPwd(params, cb) {
  params.productId =productId;
  params.transactionPwd = md5.hex_md5(params.transactionPwd + "{" + params.loginName + "}");
  params.domainName = window.location.host;
  params._dealSelf = true;
  return fetch({
    url: '/customer/validateTransactionPwd.do',
    method: 'post',
    data: params
  }, cb);
}

export function checkTransactionPwd(params, cb) {
  params.productId =productId;
  params.domainName = window.location.host;
  return fetch({
    url: '/customer/checkTransactionPwd.do',
    method: 'post',
    data: params
  }, cb);
}

export function modifyTransactionPassword(params, cb) {
  params.productId =productId;
  params.transactionPwd = md5.hex_md5(params.transactionPwd + "{" + params.loginName + "}");
  params.domainName = window.location.host;

  return fetch({
    url: '/customer/updateTransactionPwd.do',
    method: 'post',
    data: params
  }, function (res) {
    cb(res);
  });
}

export function modifyPassword(params, cb) {
  params.productId =productId;
  params.pwd = md5.hex_md5(params.pwd + "{" + params.loginName + "}");
  params.domainName = window.location.host;

  return fetch({
    url: '/customer/modifyPassword.do',
    method: 'post',
    data: params
  }, function (res) {
    let message = res.data;
    if (!message.fail) {
      loginInfo.token = message.data;
      loginInfo.loginName = params.loginName;
    }
    cb(res);
  });
}

export function queryMsg(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/customer/queryCustomerMsg.do",
    method: 'post',
    data: params
  }, cb);
}

export function modifyMsg(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/customer/modifyCustomerMsg.do",
    method: 'post',
    data: params
  }, cb);
}

// export function createCallback(params, cb) {
//   params.loginName = loginInfo.loginName;
//   params.productId =productId;
//   // params.requestId = store.state.verifyCodeRequestId;
//   params.token = loginInfo.token;
//   return fetch({
//     url: "/customer/backCall.do",
//     method: 'post',
//     data: params
//   }, cb);
// }

export function queryAgentContracts(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/config/queryAgentContracts.do",
    method: 'post',
    data: params
  }, cb);
}

export function queryCustProportionLimit(params, cb) {
  params.productId =productId;

  return fetch({
    url: "/customer/queryCustProportionLimit.do",
    method: 'post',
    data: params
  }, cb);
}

export function queryCustProportion(params, cb) {
  params.productId =productId;

  return fetch({
    url: "/customer/queryCustProportion.do",
    method: 'post',
    data: params
  }, cb);
}

// customer/info/saveCustProportion

export function saveCustProportion(params, cb) {
  if (!usePublic) {
    params.productId =productId;
  }

  return fetch({
    url: "/customer/saveCustProportion.do",
    method: 'post',
    data: params
  }, cb);
}

export function custProportionCommission(params, cb) {
  // params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/custProportionCommission/findPage.do",
    method: 'post',
    data: params
  }, cb);
}

export function findPageProportionContribution(params, cb) {
  params.productId =productId;

  return fetch({
    url: "/custProportionCommission/findPageProportionContribution.do",
    method: 'post',
    data: params
  }, cb);
}

export function saveProportionCommission(params, cb) {
  // params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/custProportionCommission/update.do",
    method: 'post',
    data: params
  }, cb);
}

export function querySiteDirectLine(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;

  return fetch({
    url: "/customer/querySiteDirectLine.do",
    method: 'post',
    data: params
  }, cb);
}

export function saveNoticeMessage(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  return fetch({
    url: "/noticeMessage/save.do",
    method: 'post',
    data: params
  }, cb);
}

export function statisticsDownload(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  params.terminal = "PC";
  return fetch({
    url: "/material/statisticsDownload.do",
    method: 'post',
    data: params
  }, cb);
}

export function deleteAgentCommissionRecords(params, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  return fetch({
    url: "/commission/deleteAgentCommissionRecords.do",
    method: 'post',
    data: params
  }, cb);
}

export function switchAccount() {
  let params = {
    loginName: loginInfo.loginName,
    productId:productId,
    uiMode: (loginInfo.uiMode === 'CNY') ? 'USDT' : 'CNY',
    accountType: (loginInfo.uiMode === 'CNY') ? 2 : 1
  }

  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);

  return requestPublicGateway({
    method: 'post',
    url: '/customer/switchAccount',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  }).then(res => {
    if (res.data.head.errCode !== '0000') {
      Message({
        message: res.data.head.errMsg,
        type: 'error'
      });
      return;
    }
    if (res.data.body) {
      loginInfo.loginName = res.data.body.loginName;
      loginInfo.uiMode = params.uiMode;
      saveLoginInfoToStorage()
      storage.setItem('currency', Number(res.data.body.subAccountFlag));
      store.commit('currency', Number(res.data.body.subAccountFlag));
    }
  })
}

export function preLogin(params = {}) {
  params.productId =productId;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/customer/preLogin',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  }).then(res => {
    if (res.data.body) {
      store.commit('captchaConfig', res.data.body);
    }
  })
}

// 生成汉字验证码
export function getClickCaptcha(params = { use: 1 }) {
  params.productId =productId;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/captcha/generateCaptcha',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  })
}

// 校验汉字验证码
export function validateClickCaptcha(params = { captcha: '', captchaId: '', use: 9 }) {
  params.productId =productId;
  params.loginName = loginInfo.loginName;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/captcha/validateCaptcha',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  })
}

// 获取系统变量
export function getDynamicData(params = {}) {
  params.productId =productId;
  params.loginName = loginInfo.loginName;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/dynamic/query',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    }
  })
}

// 异地登录
export function loginWith2FA(params = {}) {
  params.productId =productId;
  params.phase = 2;
  params.type = 1;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/customer/loginWith2FA',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    }
  })
}

// 通过用户名发送验证码
export function sendCodeByLoginName (params = {}) {
  params.productId =productId;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/phone/sendCodeByLoginName',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    }
  })
}

export function createCallback(params = {}, cb) {
  params.loginName = loginInfo.loginName;
  params.productId =productId;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/callback',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  }).then(res => {
    cb(res)
  })
}

export function verifyTempAuthTicket(params = {}, cb) {
  params.productId =productId;
  let qid = randomFn();
  let sign = publicMd5(sortString(JSON.stringify(params)) + qid + appId + version + webToken.token + webToken.u2token);
  return requestPublicGateway({
    method: 'post',
    url: '/verifyTempAuthTicket',
    data: params,
    headers: {
      'Content-Type': 'application/json',
      'AppId': appId,
      'Qid': qid,
      'v': version,
      'Sign': sign,
      'token': webToken.token
    },
  }).then(res => {
    let message = res.data;
    if (res && message.head.errCode === '0000') {
      let webToken = base64decode(message.body.info)
      sessionStorage.setItem('webToken', webToken);
      setTimeout(() => {
        myutil.getWallet()
      });
    }
    cb(res)
  })
}

export function queryAgentProductCurrency(params, cb) {
  if (!usePublic) {
    params.productId =productId;
  }

  return fetch({
    url: "/productConstant/queryAgentProductCurrency.do",
    method: 'post',
    data: params
  }, cb);
}


//获取多钱包余额
export function queryWalletByLoginName(param={},cb) {
  let params = {
    ...param,
    productId: productId,
    loginName: loginInfo.loginName,
  };
  return fetch({
    url: '/customer/queryWalletByLoginName.do',
    method: 'post',
    data: params
  }, cb);
}


//特级代理抽佣配置查询列表（分页）
export function queryListPage(param={},cb) {
  let params = {
    ...param,
    productId: productId,
    loginName: loginInfo.loginName,
  };
  return fetch({
    url: '/specialCommissionRatio/queryListPage.do',
    method: 'post',
    data: params
  }, cb);
}


//抽佣设置修改
export function editCommissionRatio(param={},cb) {
  let params = {
    ...param,
    productId: productId,
    loginName: loginInfo.loginName,
  };
  return fetch({
    url: '/specialCommissionRatio/edit.do',
    method: 'post',
    data: params
  }, cb);
}