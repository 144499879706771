import Vue from 'vue'
import ElementUI, {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import VueI18n from 'vue-i18n';
import i18n_CN from './api/i18n_CN';
import i18n_EN from './api/i18n_EN';
import i18n_VN from './api/i18n_VI';
import router from './router';
import store from './store'
import VeeValidate, {Validator} from 'vee-validate';
import './assets/css/main.css';
import _ from './filter';
import * as myutil from './api/myutil';
import ElementLocale from 'element-ui/lib/locale';
import clipboard from 'clipboard';
import './registerServiceWorker'

Vue.prototype.clipboard = clipboard;

// __webpack_public_path__ = '/assets/assets/'
__webpack_public_path__ = window.yunweiConfig.imageServer + window.yunweiConfig.cdnPublicPath + '/';
console.log(__webpack_public_path__)

Vue.prototype.myutil = myutil;
// Vue.prototype.floatFormat = myutil.floatFormat;
// Vue.prototype.twoDig = myutil.twoDig;

Vue.use(ElementUI);
Vue.use(VueI18n);

let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'zh-cn';
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'zh-cn',//fallbackLocale,
  messages: {
    'zh-cn': i18n_CN,
    "en": i18n_EN,
    "vi": i18n_VN
  }
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.use(VeeValidate, {
  events: 'change',
  errorBagName: 'errorBags',
  fieldsBagName: 'fieldBags'
});

new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: i18n,
  render: h => h(App)
});

import * as api from './api/api';

function queryProductionConstant() {
  api.queryProductConstant(function (res) {//获取productInfo 包括添加会员时账户和密码的校验规则 这个只与产品有关且后续存在sessionStorage中，是否登录没有影响
    let message = res.data;
    if (message.fail) {
      return;
    }
    myutil.checkApiLoginToken();
    Object.keys(message.data).forEach(function (key) {//Object.keys(object)返回传入对象属性名的数组
      api.productInfo[key] = message.data[key];
    });
    let arr = api.productInfo.platform.substring(1, api.productInfo.platform.length - 1).split(',');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].trim().split(':');
      api.platform[arr[i][0].substring(1, arr[i][0].length - 1)] = arr[i][1].substring(1, arr[i][1].length - 1);
      api.platformOptions.push({
        value: arr[i][0].substring(1, arr[i][0].length - 1),
        label: arr[i][1].substring(1, arr[i][1].length - 1)
      });
    }
    api.updateCauseProductInfo(api.productInfo);
    api.saveProductInfoToStorage();
    if (!sessionStorage.getItem('loginName') || sessionStorage.getItem('loginName') === 'undefined') {
      let url = decodeURIComponent(location.href);
      if (url.indexOf('loginName') !== -1) {
        let param = url.slice(url.indexOf('?') + 1).split('&');
        let obj = {};
        for (let j = 0; j < param.length; j++) {
          let Arr = param[j].split('=');
          obj[Arr[0]] = Arr[1];
        }
        router.push({name: 'start'});
        if (obj.token) {
          sessionStorage.setItem('token', obj.token);
        }
        if (obj.info) {
          sessionStorage.setItem('webToken', base64decode(obj.info));
        }
        if (obj.loginName) {
          sessionStorage.setItem('loginName', obj.loginName);
        }
      }
    }
  });
  myutil.queryAndStoreVerifyCode();//验证码
  myutil.getAgentAccess();
}

api.requestAppToken(queryProductionConstant);

