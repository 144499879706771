import Vue from 'vue';
import Vuex from 'vuex';
import EventBus from "eventbusjs";
import VueI18n from 'vue-i18n';
import i18n_CN from './api/i18n_CN';
import i18n_EN from './api/i18n_EN';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'zh-cn',
  messages: {
    "en": i18n_EN,
    'zh-cn': i18n_CN,
  }
});

Vue.use(Vuex);
let storage = sessionStorage;

function generateUUID() {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

let storeDefault = {
  unreadMessage:[],//未读消息列表
  agentCommissionLoading:false, //查询历史佣金加载
  walletList:[],//多货币钱包
  selestCurrency:"", //当前选中货币类型
  currentWallet:{}, //当前选中钱包
  isAccessModal: false,
  isSubAccount: false,
  currency: 0,
  logined: false,
  routerList: [
    {title: i18n.t('myNav.label.home'), name: 'home', active: true}
  ],
  // 账户没有下线会员，视为firstLogin
  firstLogin: true,
  userInfo: {
    loginName: '',
    commissionCredit: 0
  },
  // 是否只是普通代理
  isGeneral: false,
  // 是否有双方案
  isSubline: false,
  // 是否有支付密码
  hasTransactionPwd: 0, // 0：没有 1：有
  //是否需要支付密码
  isNeedTransactionPwd: 0, // 0：不需要 1：需要
  currentNormalCommissionAndContribution: {//这里是普通代理 必有方案所谓的默认方案
    agentCommission: {
      _fake: true,
      agentCommission: '0',
      firstCustNum: 0,
      startDate: '',
      endDate: '',
      subline: 0,
      line: 0
    },
    oneLevelContribution: [],
    twoLevelContribution: [],
    threeLevelContribution: []
  },
  currentProfessionCommissionAndContribution: {//专业代理方案
    agentCommission: {
      _fake: true,
      agentCommission: '0',
      firstCustNum: 0,
      startDate: '',
      endDate: '',
      subline: 0,
      line: 1
    },
    oneLevelContribution: [],
    twoLevelContribution: [],
    threeLevelContribution: []
  },
  currentSublineCommissionAndContribution: { //普通代理双方案中的第二套方案
    agentCommission: {
      _fake: true,
      agentCommission: '0',
      firstCustNum: 0,
      startDate: '',
      endDate: '',
      subline: 1,
      line: 0
    },
    oneLevelContribution: [],
    twoLevelContribution: [],
    threeLevelContribution: []
  },
  currentNormalContract: {////谱代佣金方案 1
    _fake: true,
  },
  mainNormalContract: {////主账号谱代佣金方案 1
    _fake: true,
  },
  currentProfessionContract: {//专代佣金方案
    _fake: true,
  },
  currentSublineContract: {//谱代佣金方案 2
    _fake: true,
  },
  effectiveCust: {
    _current: false,
    startDate: '',
    endDate: '',
    commissionIssueId: '',
    loading: false,
    pageNum: 1,
    pageSize: 20,
    totalCount: 0,
    list: []
  },
  customerDynamicInfo: {
    noLoginCustomerNum: 0,
    totalCustomerNum: 0,
    newCustomerNum: 0,
    depositCustomerNum: 0,
    bigBetCustomerNum: 0,
    totalBetCustomerNum: 0,
    bigBetCustomerPer: 0,
    depositCustomerPer: 0,
    winLoseCustomerNumPer: 0,
    winLoseCustomerNum: 0,
    depositeNum: 0,
    winLose: 0,
    totalWinLose: 0,
    winlosePer: 0
  },//默认方案 会员动态  当期
  customerProfessionDynamicInfo: {
    noLoginCustomerNum: 0,
    totalCustomerNum: 0,
    newCustomerNum: 0,
    depositCustomerNum: 0,
    bigBetCustomerNum: 0,
    totalBetCustomerNum: 0,
    bigBetCustomerPer: 0,
    depositCustomerPer: 0,
    winLoseCustomerNumPer: 0,
    winLoseCustomerNum: 0,
    depositeNum: 0,
    winLose: 0,
    totalWinLose: 0,
    winlosePer: 0
  },// 专代会员动态  当期
  customerSublineDynamicInfo: {
    noLoginCustomerNum: 0,
    totalCustomerNum: 0,
    newCustomerNum: 0,
    depositCustomerNum: 0,
    bigBetCustomerNum: 0,
    totalBetCustomerNum: 0,
    bigBetCustomerPer: 0,
    depositCustomerPer: 0,
    winLoseCustomerNumPer: 0,
    winLoseCustomerNum: 0,
    depositeNum: 0,
    winLose: 0,
    totalWinLose: 0,
    winlosePer: 0
  }, //普通方案2 会员动态 当期
  customerDetail: {
    totalDeposit: 0,
    totalBetLose: 0,
    totalBet: 0,
    betTimes: 0,
    plateFormBetDetailList: [],
    gameKindBetDetailList: [],
    lastLoginDate: '',
    createdDate: '',
    bigBetTimes: 0,
    bigBetTimesPer: 0,
    betTimesPer: 0,
    domainName: ''
  },
  agentCommissionRecordMain: {},
  customerContributionMain: {},
  verifyCodeRequestId: generateUUID(),
  verifyCode: null,
  notincommission0: 0,
  notincommission1: 0,
  announcement: '',
  captchaConfig: {needCaptcha: 0}
};

function storeInit(name) {
  let value = storage.getItem(name);
  if (value === null) {
    return storeDefault[name];
  }
  if (value.startsWith("{")) {
    return JSON.parse(value);
  } else {
    try {
      return JSON.parse(value);
    } catch (e) {
      return storeDefault[name];
    }
  }
}

function saveToStorage(state, name) {
  let value = state[name];
  if (typeof value == 'object') {
    storage.setItem(name, JSON.stringify(value));
  } else {
    storage.setItem(name, value);
  }
}

let state = {
  reset: {}
};

let keysStoreDefault = Object.keys(storeDefault);

keysStoreDefault.forEach(function (key) {
  if (state[key] == undefined) {
    state[key] = storeInit(key);
  }
});

let mutations = {
  reset(state, value) {
    keysStoreDefault.forEach(function (key) {
      state[key] = storeDefault[key];
      saveToStorage(state, key);
    });
  },
};

let keysState = Object.keys(state);

keysState.forEach(function (key) {
  if (mutations[key] == undefined) {
    mutations[key] = function (state, value) {
      state[key] = value;
      saveToStorage(state, key);
    }
  }
});

const store = new Vuex.Store({
  state: state,
  mutations: mutations
});

EventBus.addEventListener("toRelogin", function () {
  store.commit('reset', null);
});
export default store;
