<template>
  <div class="selectCurrency">
    <span class="label" :class="{global}" v-if="label">{{ label }}</span>
    <div class="selectBox">
      <el-select v-model="selectValue" placeholder="请选择" :clearable="clearable" :disabled="$attrs.disabled" :size="$attrs.size||''">
        <el-option
          v-for="(item,index) in options"
          :key="item.value+index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { storeCommit } from "../../api/myutil";
export default {
  props: {
    colon: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    value: {
      type: String,
      defalut: () => {
        return "";
      },
    },
    label: {
      type: String,
      default: () => {
        return "货币类型";
      },
    },
    all: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    global: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    immediate:{
      type:Boolean,
      default:()=> {
        return false
      }
    },
    clearable:{
      type:Boolean,
      default:()=>{
        return false
      }
    }
  },
  data() {
    return {};
  },
 
  computed: {
    ...mapState(["walletList", "selestCurrency", "currentWallet"]),
    selectValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.handleSelect(val);
        if (this.global) {
          storeCommit("selestCurrency", val);
          const currentWallet = this.walletList.find((v) => v.currency === val);
          storeCommit("currentWallet", currentWallet);
        }
      },
    },
    options() {
      if (this.all) {
        return [{ label: "全部", value: "" }, ...this.walletList];
      } else {
        return this.walletList || [];
      }
    },
  },
  mounted() {
    if (!this.selectValue && this.immediate) {
      this.handleSelect(this.currentWallet.currency || "USDT");
    }
  },
  methods: {
    handleSelect(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less" scoped>
.selectCurrency {
  display: flex;
  justify-items: center;
  align-items: center;
  .label {
    font-size: 14px;
    white-space: nowrap;
    margin-right: 8px;
  }
  .global{
    color: #fff;
    margin-right: 8px;
  }
  .selectBox {
    width: 100px;
  }
}
</style>