import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    ...enLocale,
    public: {
        label: {
            currencyType:"Currency Type",
            loginName: 'Username',
            password: 'Password',
            transactionPassword:"Transaction Password",
            realName: 'RealName',
            verificationCode: 'Verification Code',
            remarks: "Remarks",
            submit: "Submit",
            done: 'Done',
            notice: 'Notice',
            colon: ':',
            comma: ', ',
            period: ".",
            openParenthesis: ' (',
            closeParenthesis: ') ',
            commissionType0: "Commission Ratio",                        /*Note: site and agent ratio*/
            commissionType1: "Agent Partial Commission",                /*Note: agent commission whether win or lose*/
            inputUsername: 'Please enter username',
            loading: 'Loading',
            noData: 'No Data',
            status: 'Status',
            people: ' people',
            amount: 'Amount',
            parentName: "Upline Name",
            systemAccountOpening: "System Registered",
            generalizationCode: "Promotional Code",
            exclusiveDomainName: "Exclusive Domain Name",
            bigProfit: 'Big Profit',
            transaction: {
                deposit: "Deposit",
                withdraw: "Withdraw",
                commissionGame: "Commission to Game",
                commissionDownline: "Transfer to Downline Commission",
                cashToCommission: "Cash to Commission",
                commissionToGame: 'Commission to Game',
                commissionWithdrawals: 'Commission Withdrawals',
                commissionToDownline: 'Commission to Downline',
                cancelCashToCommission: 'Cancel Cash to Commission',
                gameCurrencyPoints: 'Game Currency Points',
                cancelWithdrawal: 'Cancel Withdrawal',
                commissionsEarned: 'Commissions Earned',
                proAgentCommissionsEarned: 'Professional Agent Commissions Earned',
                modifyCommission: 'Modify Commission',
                onlineTransfer: "On-line transfer"
            },
            time: {
                registerTime: 'Register Time',
                depositTime: 'Deposit Time',
                withdrawTime: 'Withdraw Time',
                loginTime: 'Login Time',
                betTime: 'Bet Time',
                profitTime: 'Profit Time',
                lastLoginTime: 'Last Login Time'
            },
            registrationDate: 'Registered Date',
            registerFromType: 'Registration termina',
            startDate: 'Start Date',
            endDate: 'End Date',
            registStartDate: 'Register Start Date',
            registEndDate: 'Register End Date',
            separator: 'to',
            search: 'Search',
            lastSevenDays: 'last 7 Days',
            lastOneMonth: 'last 30 Days',
            commissionDetail: "Commission Details",
            memberDetails: "Member's Details",
            index: "Index",
            gameKind: "Game Type",
            firstLine: "Level 1",
            oneLine:"Level 1",
            secondLine: "Level 2",
            thirdLine: "Level 3",
            all: "All",
            others: "Others",
            cycle: {
                cycleType: "Cycle",
                day: "Daily",
                week: "Weekly",
                halfMonth: "Half month",
                month: "Monthly",
            },
            game: {
                sports: "Sports Game",
                eBets: "E-bets",
                realPeople: "Live Casino",
                videoGame: "E-game",
                mahjong: "Mahjong",
                battle: "Battle Game / Texas Poker",
                fishing: "Fishing Game",
                eSports: "E-sports",
                lottery: "Lottery",
                threeD: "3D",
                poker: "poker",
            },
            channel: {
                qq: "QQ",
                wechat: "WeChat",
                facebook: "Facebook",
            },
            starLevel: 'Star Level',
            validID: 'Valid ID',
            registeredSource: 'Registered Source',
            registerIp: 'registerIp'
        },
        status: {
            settled: "Settled",
            unsettled: "Unsettled",
            recorded: "Recorded",
            unrecorded: "Unrecorded",
            enabled: 'Enabled',
            disabled: 'Disabled',
            standard: "Are standard met",
            isStandard: "Standard met",
            noStandard: "Standard not met",
            reject: "Reject",
            inSettlement: 'In Settlement',
            badDebt: 'Bad Debt'
        },
        button: {
            login: "Login",
            confirm: "Confirm",
            cancel: 'Cancel',
            back: "Go Back",
            settlement: "settlement",
            selectAll: "Select All"
        },
        msg: {
            error: {
                fillCorrectInfo: 'Please fill in the correct information!',
                inputPassword: 'Please input password',
                confirmPassword: 'Please re-enter password',
                pwdNotMatch: 'Password and confirm password do not match!',
                copy: 'Failed to copy. Please copy manually!',
                inputAmount: 'Please enter an amount',
                validAmount: 'Amount must be in number, with a maximum of two decimal places',
                validAmountBTC: 'Amount must be in number, with a maximum of four decimal places',
                validBalance: 'The amount must not exceed the account balance',
                passwordError: 'Incorrect password. Please try again!',
                login: 'Login timeout, please login again',
                unknown: 'Unknown error please try again',
                verificationCode: "Unable to refresh verification code. Please clear your web browser's cache",
                networkTimeout: 'Network Connection Timeout',
                requestedAddress: 'The requested Address was not found, please check!',
                appToken: 'Invalid app_token',
                remarks: 'Please enter notes'
            },
            success: {
                copy: 'Successfully copied!',
                modify: 'Modified success!',
                settled: 'Successful settlement'
            },
            pwd: {
                input: 'Please input ',
                numbers: ' characters consisting of both numbers',
                or: ' or ',
                and: ' and ',
                characters: 'letters'
            },
            range: {
                between: "Amount must be between the range of ",
                to: " to ",
                scope: ""
            }
        },
        priorityLevel: {
            discount: "discount",
            arbitrage: "arbitrage",
            test: "test",
            offline: "offline",
            attention: "attention",
            blacklist: "blacklist",
            ordinary: "ordinary",
            whiteList: "whiteList"
        }
    },
    addMember: {
        label: {
            requiredInformation: "Required Fields",
            name: "Real Name",
            confirmPassword: "Confirm Password",
            telPhoneNum: "Cellphone Number",
            email: "Email Address",
            selectiveInformation: "Optional Information",
            sex: "Gender",
            male: "M",
            female: "F",
            continueAdd: "Add New Member",
            addNewMember: 'Are you sure you want to add new member?'
        },
        placeholder: {
            remarks: 'Enter a maximum of 20 characters'
        },
        msg: {
            error: {
                qqEmail: 'Please fill in the correct QQ email',
                inputValidPhone: 'Please enter a valid phone number',
                accountExists: 'Account already exists',
                emailAddress: 'Please fill in your email address',
                emailFormat: 'Invalid email address format',
                realName: 'Please enter your real name',
                mandatory: 'This is a required field'
            },
            success: {
                addNewMember: 'New members successfully added '
            }
        }
    },
    modifyPassword: {
        label: {
            oldPassword: "Old Password",
            newPassword: "New Password",
            rePassword: "Confirm Password",
            confirmChangePwd: 'Please confirm whether to set this transaction password?'
        },
        button: {
            loginAgain: 'Login Again',
            later: 'Later'
        },
        msg: {
            error: {
                oldPassword: 'Incorrect old password',
                changePwd: 'Change password failed, please try again!'
            },
            success: {
                changePwd: 'Password successfully changed, login again?'
            }
        }
    },
    app: {
        label: {
            modifyPassword: "Modify Login Password",
            modifyTransactionPassword: "Modify Transaction Password",
            logout: "Logout",
            logoutAccount: "Logout Account",
            balance: "Balance",
            transactionRecord: "Transaction",
            download: "Download",
            language: "Language",
            message: "Msg",
            customerService: "CSR",
            contactNumber: "Contact Number",
            contact: "Contact",
            applyCallback: "Call Me Back",
            messageTitle: "Message",
            messageTitleDetail: "Details",
            freeCallBackTitle: "Free Call Back",
            getCommission: "Get High Contract",
            read: 'Read',
            unread: 'Unread',
            msgDetailTitle: 'Title',
            msgDetailContent: 'Content',
            title: 'Title',
            date: 'Date'
        },
        button: {
            delete: 'Delete',
            deleteRecord: 'Delete record'
        },
        msg: {
            error: {
                contactNumber: 'Please enter 11-12 digits'
            },
            success: {
                delete: 'Delete Completed!',
                callbackSent: 'Please wait for a moment, a customer service representative will contact you later!',
                getCommissionSent: 'Ur contactNum already submit，we will contact u soon'
            }
        }
    },
    login: {
        label: {
            title: "Please enter your username and password",
            loginName: "Username",
            contactCS: "Contact Customer Service",
            rights: "AG sub-brand © 2017 Huanya entertainment edition all rights reserved",
            productName: "凯时合作伙伴",
            welcome: "欢迎使用 乐橙代理系统",
            c07_welcome: "欢迎使用",
            a01_name: "共赢天下",
            a04_name: "财富伙伴",
            c07_name: "",
            b07_welcome: "欢迎使用 利来电游共赢系统"
        },
        msg: {
            error: {
                matchUserPwd: 'Username and password do not match'
            },
            success: {
                login: 'Login Successful'
            }
        }
    },
    links: {
        label: {
            tips: "You can also",
            addMember: "Add New Member",
            exclusivePromotionDomainName: "Exclusive Promotion Domain Name",
            identificationCode: "Identification Code",
            replicationLink: "Copy Link",
            promotionDomainName: "Promotion Domain Link",
            qrCode: "QR Code"
        }
    },
    home: {
        label: {
            calculationDescription: "Calculation Description of Commission",
            validMember: "Valid Member",
            commission: "Commission",
            professionalAgent: "Professional Agent",
            ordinaryAgentOption1: "General Agent (Method 1)",
            ordinaryAgentOption2: "General Agent (Method 2)",
            ordinaryAgentMonth: "General Agent (Monthly)",
            ordinaryAgentHalfMonth: "General Agent (Half Month)",
            ordinaryAgentWeek: "General Agent (Weekly)",
            ordinaryAgentDay: "General Agent (Daily)",
            centralLink: "From previous",
            establishAccount: "Establish Account",
            newEstablishAccount: "New accounts",
            newDepositNum: "Deposit of New Account",
            depositRatio: "Initial deposit ratio of new accounts",
            newMemberDeposit: "Total Deposit of New Account",
            newMemberTotalDeposit: "Total deposit of new accounts",
            totalDeposit: "Deposit",
            winLose: "Win / Lose",
            totalWinLose: "Member's win / lose",
            bigBet: "Large Bets",
            bigBetNum: "Number of large bets",
            totalWithdraw: "Withdraw",
            totalContribution: "Total Members Contribution",
            firstContribution: "1st Level Members Contribution",
            secondContribution: "2nd Level Members Contribution",
            thirdContribution: "3rd Level Members Contribution",
            goCommissionProposal: "Current Commission Details",
            dynamic: 'Member Dynamic Update',
            currentCommissionBudget: "Current Commission Budget",
            currentCommission: "Current Commission",
            historyCommission:"Historical Commission",
            netProfit: "Net Profit",
            commissionRate: "Commission Rate",
            reportProfit: "Report Profit",
            platformCost: "Platform Cost",
            capitalCost: "Capital Cost",
            rebate: "Rebate",
            discount: "Promo",
            exchangeRate:"Exchange rate of the day",
            date:"Date",
            remark:"Remark",
            profitRevision: "Profit Revision",
            promotionCost: "Promotion Cost",
            otherCost: "Other Cost",
            income: "Income",
            bettingAmount: "Bet on Rebate",
            contribution: "Member's Contribution",
            profit: "Profit",
            level: "Level",
            bet: "Bet",
            current: "Current",
            and: " and ",
            or: " or ",
            game: 'Game ',
            amortizedCostRatio: "Amortized Cost Rate",
            commissionRatio: "Commission Ratio",
            commissionFormula: "Commission Formula",
            upgradeCondition: "Upgrade Condition",
            commissionRules: "Commission Rules",
            priorityLevel: "priority"
        },
        msg: {
            error: {
                currentCommissionDataError: 'Current Commission Data Error',
                tryAgain: 'Please try again'
            }
        },
        condition: {
            standard: " standard",
            msg: "after reaching the standard, the commission shall be calculated according to the highest standard gear"
        }
    },
    firstLogin: {
        label: {
            baseInfo: "Basic Information",
            modifyPlan: "Modify Method",
            settlementCycle: "Settlement Cycle",
            hello: "Hello",
            welcome: "Welcome to ",
            proAgentMethod: 'Professional Agent Method',
            generalMethod: 'General Method ',
        },
        cycle: {
            weekly: 'Week ',
            monthly: 'Monthly Day ',
            day: ''
        }
    },
    turnGame: {
        label: {
            continue: 'Continue',
            confirmTransfer: 'Are you sure to transfer this amount ',
            question: ' to game?'
        }
    },
    withdraw: {
        label: {
            selectBank: 'Please select a recipient bank',
            selectBank1: 'Please select a bank card to bind',
            selectBank2: 'Recipient bank card',
            goWebsite: 'Go to official website to bind a bank card',
            Continue: 'Continue',
            confirmWithdraw: 'Are you sure you want to withdraw this amount ',
            question: ' ?'
        },
        placeholder: {
            withdraw: 'Maximum of two decimal places',
        }
    },
    turnLine: {
        label: {
            downlineName: 'Downline Username',
            select: 'Select',
            goOn1: 'Continue',
            goOn2: 'Continue',
            selectDownName: 'Search Downline',
            placeholder: 'Please input downline username',
            lastWeek: 'Last Week',
            lastMonth: 'Last 1 Month',
            lastThreeMonth: 'Last 3 Months',
            confirmMsg: "Are you sure you want to transfer the amount to your downline?",
            confirmMsg1: "Are you sure you want to transfer the amount to your downline?",
        }
    },
    finance: {
        label: {
            tips: "(The sum of weekly and monthly statement commission for the current period)",
            budget: "Revenue and Expenditure",
            generalAgencyCommission: "General Agent Commission",
            professionalAgencyCommission: "Professional Agent Commission",
            professional: "Pro"
        }
    },
    transactionRecord: {
        label: {
            requestID: "Request ID",
            reviewing: "Reviewing",
            preTransactionBalance: "Pre-Transaction Balance",
            currentBalance: "Current Balance",
            currencySuffix: "RMB",
            date: 'Date',
            allTypes: 'All Types',
            waiting: 'Waiting',
            paying: 'Paying'
        },
        placeholder: {
            requestID: "Input Request ID",
            activityType: 'Activity Type',
        }
    },
    material: {
        label: {
            mobileQRCode: "Mobile QR Code",
            url: "URL",
            copyURL: "Click to copy URL",
            download: "Click to download",
            image: "Image",
            tweet: "Tweet",
            video: "Video",
            audio: "Audio",
            file: "File",
        },
        placeholder: {
            materialName: "Material Name",
            selectGameType: 'Game Type',
            selectMaterialType: 'Game Type',
            selectChannel: 'Game Type'
        }
    },
    tools: {
        label: {
            forward: "One-click forwarding",
            marketing: "Marketing System",
            groupControl: "Group Control System"
        }
    },
    promotion: {
        label: {
            historyOverview: 'History Overview',
            domainName: 'Domain Name',
            initialDepositPeople: 'Initial Deposit People',
            registeredPeople: 'Registered People',
            initialDepositAmount: "Initial Deposit Amount",
        }
    },
    commission: {
        label: {
            firstAgentLevelDetail: "Level 1 Members Contribution Details",
            secondAgentLevelDetail: "Level 2 Members Contribution Details",
            thirdAgentLevelDetail: "Level 3 Members Contribution Details",
            commissionContribution: "Contribution",
            modifyProfit: "Modify Profit",
            case1: "Method 1",
            case2: "Method 2",
            firstEffectiveCustNum: "Direct Valid Member",
            agentCommission: "Estimated Current Commission",
            lastAgentCommission: "Previous Debt",
            accountsPayable: "Estimated Receivable Amount",
            firstCommission: "1st Level Members Contribution",
            secondCommission: "2nd Level Members Contribution",
            thirdCommission: "3rd Level Members Contribution",
            viewDetails: 'View Details',
            gameRatioDetails: 'Sub-game Commission Ratio Details',
            lastLoss: "Historic Negative Profit"
        }
    },
    management: {
        label: {
            totalBet: "Valid Bets",
            totalWinLose: 'Total Win / Lose',
            memberAccount: "New Open Account Statistics",
            rate: "Rate",
            similarMethodRatio: 'Similar Method Ratio',
            increaseRate: 'Increase Rate',
            increaseAmount: 'Increase Amount'
        }
    },
    dynamic: {
        label: {
            bet: "Bet",
            profit: "Profit",
            totalLargeBets: "Total Large Bet",
            totalLargeProfit: "Total Large Profit",
        },
        customize: {
            thisWeek: 'This week at',
            timesLogin: 'times login',
            thisIsYour: 'This is your',
            member: 'th member'
        }
    },
    statistics: {
        label: {
            count: "Count",
            rangeDate: "Initial Time",
            withdrawCount: "# Withdrawals",
            depositCount: "# Deposits"
        }
    },
    profit: {
        label: {
            gameKindAll: "Game Type",
            platformAll: "Game Platform",
            platform: "Platform",
            winLoseValue: "Win / Lose Value"
        }
    },
    myNav: {
        label: {
            home: "Home",
            proManagement: "Pro-Agent",
            proCurrentCommission: "Pro Agent Current",
            proCommissionProposal: "Pro Agent Proposal",
            finance: "Financial",
            commissionProposal: "Commission Proposal",
            management: "Member",
            dynamic: "Dynamic Update",
            statistics: "Statistics",
            information: "Information",
            proDownLine: "Pro Agent Downline",
            promotion: "Promotion",
            commissionSettings:"Commission Settings",
            systemManagement:"systemManagement",
            occupancySettings:"Occupancy Settings",
            links: "Links",
            material: "Materials",
            tools: "Tools / Ways",
            memberWinLose: "Win / Lose",
            proProportionProposal: "Divide Proposal",
            proProportionCurrentCommission: "Divide Current",
            commissionData: "Commission data",
            proCommissionData: "Professional Commission data"
        }
    },
    information: {
        label: {
            selectStarLevel: 'Star Level',
            selectValidID: 'Select ID',
            downlineHierarchy: 'Downline Hierarchy',
            enabled: 'Enabled',
            disabled: 'Disabled',
            custRate: 'revenue ratio',
            level: 'level',
            setCustRate: 'set revenue ratio',
            allGame: 'all game',
            details: 'Downline Contribution Details'
        },
        placeholder: {
            username: 'Username',
            registeredSource: 'Source',
            downLineParent: 'Parent loginName'
        }
    },
    proDownline: {
        label: {
            numDirectReferrals: 'Direct Referral',
            commissionStatus: 'Commission Status'
        }
    }
}
