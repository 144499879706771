<template>
  <div id="agentLink" class="d-flex flex-column align-items-center">
    <loading-page ref="loading-com" :tipsTxt="tips" :speed="speed" ></loading-page>
  </div>
</template>

<script>
import loadingPage from '@/components/agent3m/loading.vue'
import {base64decode, loginAgent, loginInfo, saveLoginInfoToStorage, verifyTempAuthTicket} from '../../api/api'
import * as myutil from "@/api/myutil";
import * as api from "@/api/api";
import store from "@/store";

export default {
  name: 'AgentLink',
  data () {
    return {
      errorMessage: '',
      speed: 0,
      count: 0,
      ticket: '',
      loginName: '',
      loginData: '',
      routerList: this.$store.state.routerList
    }
  },
  components: {
    loadingPage
  },
  computed: {
    tips () {
      // 有错误信息显示错误信息
      if (this.errorMessage) {
        return this.errorMessage
      }

      return '正在为您跳转界面，请稍候…'
    }
  },
  created () {
    const that = this
    try {
      this.loginData = JSON.parse(window.sessionStorage.getItem('3m'))
    } catch (e) { }

    // 检查传入参数
    this.$nextTick(() => {
      if (this.loginData) {
        this.ticket = this.loginData.ticket
        this.loginName = this.loginData.loginName
        this.isFrom3M = (this.loginData)
        window.sessionStorage.removeItem('3m') // 登录成功后再删除
        setTimeout(() => {
          that.verifAuthTicket()
        },1000)
      }
    })
  },
  methods: {
    verifAuthTicket () {
      const that = this
      verifyTempAuthTicket({
        ticket: that.ticket,
        loginName: that.loginName
      }, (res) => {
        let message = res.data;
        if (message.body) {
          that.loginName = message.body.loginName
          api.loginInfo.isMerChant = true
          api.loginInfo.loginName = that.loginName;
          api.loginInfo.uiMode = message.body.uiMode
          api.loginInfo.uiModeOptions = message.body.uiModeOptions
          api.saveLoginInfoToStorage();
          store.commit('currency', Number(res.data.body.uiMode === 'USDT'));
          api.loginAgent({ loginName: that.loginName, productId: productId }, function (res) {
            that.loginSuccess(that.loginName)
          })

        } else {
          console.log(message.head.errMsg)
          that.$refs['loading-com'].stopLoading()
          that.errorMessage = message.head.errMsg + '!!!'
        }
      })
    },
    loginSuccess (loginName) {
      const that = this
      setTimeout(function () {
        that.$router.push({ name: 'home' });
      }, 0);
      const loading = that.$loading({
        lock: true,
        background: 'rgba(0, 0, 0, .8)'
      });
      myutil.queryAndStoreCommonInfo(that, {loginName});
      setTimeout(function () {
        let param = {
          agentName: loginName,
          agentLevel: 1
        };
        api.countAllDownline(param, function (res) {
          let message = res.data;
          if (message.fail) {
            that.$message(message.msg);
            return;
          }
          that.$store.commit('logined', true);
          if (message.data > 0) {
            that.$store.commit('firstLogin', false);
          } else {
            that.$store.commit('firstLogin', true);
          }
          loading.close();
          that.loading = false;
          that.$message({
            type: 'success',
            message: that.$t('login.msg.success.login')
          });
          setTimeout(function () {
            that.myClick('turnLine', that.$t('public.label.transaction.gameCurrencyPoints'))
          }, 1000);
        });
      }, 1000);
    },
    myClick: function (path, name) {
      const that = this
      let isAdd = true;
      this.routerList.forEach(function (item) {
        item.active = false;
        if (item.title === name) {
          isAdd = false;
          item.active = true;
        }
      });
      if (isAdd) {
        let obj = {
          title: name,
          name: path,
          active: true
        };
        this.routerList.push(obj);
        this.$store.commit('routerList', this.routerList);
      }
      myutil.routerPosition();
      that.$router.push({ name: 'turnLine' });
    },
  },
  async mounted () {}
}
</script>
