import viLocale from 'element-ui/lib/locale/lang/vi'
export default {
    ...viLocale,
    public: {
        label: {
            currencyType:"loại tiền tệ",
            loginName: "Tài khoản",
            password: "Mật khẩu",
            transactionPassword:"Mật khẩu thanh toán",
            realName: "Họ Tên Thật",
            verificationCode: 'Mã xác nhận',
remarks: "Ghi chú",
submit: "Gửi",
done: "Hoàn Thành",
notice: 'Gợi ý',
colon: "：",
    comma: '，',
    period: "。",
    openParenthesis: '（',
    closeParenthesis: '）',
    commissionType0: "Lợi Nhuận",
commissionType1: "Doanh thu cược",
inputUsername: 'Nhập Tên Truy Cập',
loading: 'Đang tải',
noData: 'Không có dữ liệu',
status: 'Trạng Thái',
people: '',
amount: 'Số tiền',
parentName: "Tài khoản cấp trên",
systemAccountOpening: "Hệ thống tạo tài khoản",
generalizationCode: "Mã quảng cáo",
exclusiveDomainName: "Tên miền cá nhân",
bigProfit: 'Thắng lớn',
transaction: {
    deposit: "Gửi tiền",
    withdraw: "Rút tiền",
    commissionGame: "Chuyển khoản game",
    commissionDownline: "Chuyển Cấp dưới",
    cashToCommission: "Tiền mặt chuyển hoa hồng",
    commissionToGame: 'Hoa hồng chuyển game',
    commissionWithdrawals: 'Rút hoa h?ng',
    commissionToDownline: 'Hoa hồng chuyển cấp dưới',
    cancelCashToCommission: 'Hủy tiền mặt chuyển hoa hồng',
    gameCurrencyPoints: 'Đại lý tiền tệ cộng tiền',
    cancelWithdrawal: 'Hủy rút tiền',
    commissionsEarned: 'Hoa Hồng ghi có',
    proAgentCommissionsEarned: 'Hoa Hồng Đại lý chuyên ghi có',
    modifyCommission: 'Hoa hồng điều chỉnh',
    onlineTransfer: "Truyền trực tuyến"
},
time: {
    registerTime: 'Thời gian mở tài khoản',
    depositTime: 'Thời gian gửi tiền',
    withdrawTime: 'Thời gian rút tiền',
    loginTime: 'Thời gian đăng nhập',
    betTime: 'Thời gian đặt cược',
    profitTime: 'Thời gian thắng cược',
    lastLoginTime: 'Thời gian đăng nhập cuối'

},
registrationDate: 'Thời gian đăng ký',
            registerFromType: 'Máy cuối nhập cảnh',
startDate: 'bắt đầu',
endDate: 'Kết thúc',
registStartDate: 'Thời gian đăng ký bắt đầu',
registEndDate: 'Thời gian đăng ký kết thúc',
separator: 'đến',
search: 'tìm',
lastSevenDays: '7 ngày gần nhất',
lastOneMonth: '30 ngày gần nhất',
commissionDetail: "Chi tiết hoa hồng",
memberDetails: "Chi tiết thành viên",
index: "Thứ tự",
gameKind: "Loại Game",
firstLine: "Thành viên trực tiếp",
oneLine:"Thành viên trực tiếp",
secondLine: "Thành viên cấp 2",
thirdLine: "Thành viên cấp 3",
all: 'Tất cả',
others: "Khác",
cycle: {
    cycleType: "Chu kỳ",
    day: "Theo Ngày",
    week: "Theo Tuần",
    month: "Theo Tháng",
    halfMonth: "Nữa Tháng",
},
game: {
    sports: "Thể Thao",
    eBets: "Cược điện tử",
    realPeople: "Casino trực tuyến",
    videoGame: "Slot",
    mahjong: "Mạt chược",
    battle: "Dezhou Porker",
    fishing: "Bắn cá",
    eSports: "E-sport",
    lottery: "Xổ số",
    threeD: "3D",
    poker: "Ngưu Ngưu",
},
channel: {
    qq: "QQ",
        wechat: "Wechat",
    facebook: "Facebook"
},
starLevel: 'Cấp Sao',
validID: 'Nhận dạng hợp lệ',
registeredSource: 'Nguồn đăng ký',
            registerIp: 'Đăng ký IP'
},
        status: {
    settled: "đã thanh toán",
    unsettled: "Chưa Thanh Toán",
    recorded: "Ghi Có",
    unrecorded: "Chưa ghi có",
    enabled: 'Đã Kích hoạt',
    disabled: 'Vô hiệu hóa',
    standard: "Đáp ứng tiêu chuẩn không",
            isStandard: "Đã đáp ứng tiêu chuẩn",
    noStandard: "Chưa đáp ứng tiêu chuẩn",
    reject: "Từ chối",
    inSettlement: 'Đang kết toán',
    badDebt: 'Nợ xấu'
},
        button: {
    login: "Đăng nhập",
    confirm: "Xác nhận",
    cancel: 'Hủy',
    back: "Trở lại",
    settlement: "Kết toán",
            selectAll: 'Mọi cuộc bỏ phiếu'
},
        msg: {
            error: {
                fillCorrectInfo: 'Vui lòng điền thông tin theo gợi ý！',
                inputPassword: 'Vui lòng Nhập mật khẩu',
                confirmPassword: 'Vui lòng nhập lại mật khẩu',
                pwdNotMatch: 'Mật khẩu không trùng khớp！',
                copy: 'Sao chép thất bại, vui lòng sao chép tay！',

                inputAmount: 'Vui lòng nhập số tiền',
                validAmount: 'Vui lòng nhập số, tối đa 2 số thập phân',
                validBalance: 'Số tiền không được vượt quá số dư',

                passwordError: 'Sai mật khẩu, vui lòng nhập lại！',
                login: 'Quá thời gian, vui lòng đăng nhập lại',

                unknown: 'Lỗi không xác định, vui lòng thử lại',

                verificationCode: 'Làm mới mã xác nhận thất bại, vui lòng xóa cookies và thử lại',
                networkTimeout: 'đường truyền kết nối quá hạn',

                requestedAddress: 'Tìm không được yêu cầu tương xứng, vui lòng kiểm tra！',
                appToken: 'app_token vô hiệu',
                remarks: 'Vui lòng nhập ghi chú'
            },
            success: {
                copy: 'Sao chép thành công！',
                modify: 'Thay đổi thành công！',
                settled: 'Thanh toán thành công！'
             },
            pwd: {
                input: 'Nhập thông tin',
                numbers: 'con số',
                or: 'hoặc',
                and: 'và',
                characters: 'ký tự chữ'
            },
            range: {
                between: 'Số tiền lớn nhất và nhỏ nhất phải từ',
                to: 'đến',
                scope: 'Trong phạm vi'
            }
            },
        priorityLevel: {
            discount: "Giảm",
            arbitrage: "Hành khách ngang",
            test: "thử",
            offline: "comment",
            attention: "chú ý",
            blacklist: "đen",
            ordinary: "tầm",
            whiteList: "danh sách trắng"
        }
},
addMember: {
    label: {
        requiredInformation: "bắt buộc điền",
        name: "Họ tên thật",
        confirmPassword: "Xác nhận mật khẩu",
        telPhoneNum: "iSố điền thoạ",
        email: "Email",
        selectiveInformation: "Lựa chọn thông tin",
        sex: "Giới tính",
        male: "Nam",
        female: "Nữ",
        continueAdd: "Tiếp tục thêm",
        addNewMember: 'Xác nhận thêm thành viên?'
    },
    placeholder: {
        remarks: 'Tối đa nhập 20 ký tự'
    },
    msg: {
        error: {
            qqEmail: 'Điền chính xác tài khoản email QQ',
            inputValidPhone: 'Điền chính xác số điện thoại',
            accountExists: 'Tài khoản đã tồn tại',
            emailAddress: 'Điền địa chỉ email',
            emailFormat: 'Định dạng Email sai',
            realName: 'Điền họ tên thật',
            mandatory: 'Bắt buộc điền'
        },
        success: {
            addNewMember: 'Thêm thành viên mới thành công'
        }
    }
},
modifyPassword: {
    label: {
        oldPassword: "Mật khẩu cũ",
        newPassword: "Mật khẩu mới",
        rePassword: "Xác nhận mật khẩu mới",

        confirmChangePwd: 'Xác nhận đổi mật khẩu？'
    },
    button: {
        loginAgain: 'Đăng nhập lại',
        later: 'Quay lại sau'
    },
    msg: {
        error: {
            oldPassword: 'Mật khẫu cũ không chính xác',
            changePwd: 'Đổi mật khẩu thất bại, vui lòng thử lại！'
        },
        success: {
            changePwd: 'Đổi mật khẩu thành công, xác nhận đăng xuất và thử đăng nhập lại?'

        }
    }
},
app: {
    label: {
        modifyPassword: "Thay đổi mật khẩu đăng nhập",
        modifyTransactionPassword: "Thay đổi mật khẩu giao dịch",
        logout: "Đăng xuất",
        logoutAccount: "Đăng xuất tài khoản",
        balance: "số dư",
        transactionRecord: "lịch sử giao dịch",
        download: "Tải xuống hướng dẫn sử dụng",
        language: "Đa ngôn ngữ",
        message: "Tin tức",
        customerService: "Hỗ trợ khách hàng",
        contactNumber: "Số điện thoại",
        contact: "Liên hệ",
        applyCallback: "Yêu cầu gọi lại",
        messageTitle: "Tin nhắn nội bộ",
        messageTitleDetail: "Chi tiết",
        freeCallBackTitle: "Điện thoại miễn phí gọi lại.",
            getCommission: "Truy xuất phương án hoa hồng giá trị cao.",
            read: 'Đã đọc',
        unread: 'Chưa đọc',
        msgDetailTitle: 'Gợi ý',
        msgDetailContent: 'Nội dung',
        title: 'Chủ đề',
        date: 'Ngày'
    },
    button: {
        delete: 'Xóa',
        deleteRecord: 'Xoá ghi chép'
    },
    msg: {
        error: {
            contactNumber: 'Nhập vào 11 đến 12 ký tự'
        },
        success: {
            delete: 'Xóa thành công！',
            callbackSent: 'Vui lòng đợi, nhân viên hỗ trợ sẽ liên hệ quý khách！',
                getCommissionSent: 'Đã gửi thông tin liên hệ của quý khách. Nhân viên hỗ trợ sẽ liên hệ lại ngay'
        }
    }
},
login: {
    label: {
        title: "Vui lòng nhập tên truy cập và mật khẩu",
        loginName: "Tài khoản",
        contactCS: "Liện hệ hỗ trợ",
        rights: "AG旗下品牌 ? 2017 环亚娱乐版权所有 all rights reserved",
            productName: "凯时合作伙伴",
            welcome: "欢迎使用 乐橙代理系统",
            a01_name: "共赢天下",
            a04_name: "财富伙伴",
            c07_name: "K8-Đại lý uy Tín- Hoa hồng 50%",
            b07_welcome: "欢迎使用 利来电游共赢系统"
    },
    msg: {
        error: {
            matchUserPwd: 'Tên truy cập và mật khẩu không trùng khớp'
        },
        success: {
            login: 'Đăng nhập thành công'
        }
    }
},
links: {
    label: {
        tips: "Quý khách có thể",
        addMember: "Thêm thành viên",
        exclusivePromotionDomainName: "Tên miền cá nhân",
        identificationCode: "Mã phân biệt",
        replicationLink: "Sao chép tên miền",
        promotionDomainName: "Tên miền chung",
        qrCode: "Mã QR"
    }
},
home: {
    label: {
        calculationDescription: "Mô tả cách tính hoa hồng",
        validMember: "Thành viên hoạt động",
        commission: "Hoa hồng",
        professionalAgent: "Đại lý chuyên",
        ordinaryAgentOption1: "Phương án hoa hồng 1",
        ordinaryAgentOption2: "Phương án hoa hồng 2",
        ordinaryAgentMonth: "Đại lý thường (Theo tháng)",
        ordinaryAgentHalfMonth: "Đại lý thường (Theo nữa tháng)",
        ordinaryAgentWeek: "Đại lý thường (Theo Tuần)",
        ordinaryAgentDay: "Đại lý thường (Theo ngày)",
        centralLink: "So sánh kỳ trước",
        establishAccount: "Mở tài khoản",
        newEstablishAccount: "Mở tài khoản mới",
        newDepositNum: "Số lượng tài khoản mới gửi tiền.",
            depositRatio: "Tỷ lệ gửi tiền mới",
        newMemberDeposit: "Tổng tiền gửi thành viên mới",
        newMemberTotalDeposit: "Tổng tiền gửi thành viên mới",

        totalDeposit: "Tổng tiền gửi",
        winLose: "Thắng thua",
        totalWinLose: "Tổng thắng thua của thành viên",
        bigBet: "Đặt cược số tiền lớn",
        bigBetNum: "Số lần đặt cược số tiền lớn",
        totalWithdraw: "Tổng tiền rút",
        totalContribution: "Tổng đóng góp của thành viên",
        firstContribution: "Đóng góp của thành viên cấp 1",
        secondContribution: "Đóng góp của thành viên cấp 2",
        thirdContribution: "Đóng góp của thành viên cấp 3",
        goCommissionProposal: "Chi tiết hoa hồng hiện tại",
        dynamic: 'Trạng thái thành viên',
        currentCommissionBudget: "Dự tính hoa hồng hiện tại",
        currentCommission: "Hoa hồng hiện tại",
        historyCommission:"Hội đồng tiền sử",
        netProfit: "Lợi nhuận ròng",
        commissionRate: "Tỷ lệ hoa hồng",
        reportProfit: "Tổng thắng thua",
        platformCost: "Phí hoạt động",
        capitalCost: "Phí Gửi và Rút",
        rebate: "Hoàn trả",
        discount: "Khuyến mãi",
        remark:"Nhận xét",
        exchangeRate:"Tỷ giá hối đoái trong ngày",
        date:"ngày",
        profitRevision: "Điều chỉnh tiền thắng",
        promotionCost: "Phí Quảng cáo",
        otherCost: "Phí khác",
        income: "Thu nhập",
        bettingAmount: "Doanh thu XM",
        contribution: "Thành viên đóng góp",
            profit: "Lợi nhuận ròng",
        level: "Cấp độ",
        bet: "Doanh thu cược",
        current: "Kỳ hiện tại",
        and: "và",
        or: "hoặc",
        game: 'Game',
        amortizedCostRatio: "Tỷ lệ khấu hao",
        commissionRatio: "Tỷ lệ hoàn hoa hồng",
        commissionFormula: "Cách tính hoa hồng",
        upgradeCondition: "Điều kiện thăng cấp",
        commissionRules: "Quy tắc hoa hồng",
        priorityLevel: "Ưu tiên"
    },
    msg: {
        error: {
            currentCommissionDataError: 'Dữ liệu hoa hồng hiện tại bất thường',
            tryAgain: 'Vui lòng thử lại'
        }
    },
    condition: {
        standard: "Đạt chỉ tiêu",
        msg: "Đạt chỉ tiêu sẽ thanh toán dựa trên cấp độ tính hoa hồng cao nhất"
    }
},
firstLogin: {
    label: {
        baseInfo: "Thông tin cơ bản",
        modifyPlan: "Thay đổi phương thức",
        settlementCycle: "Chu kỳ thanh toán",
        hello: "Xin chào",
        welcome: "Chào mừng đến với",
        proAgentMethod: 'Phương án đại lý chuyên',
        generalMethod: 'Phương án thường',
    },
    cycle: {
        weekly: 'Mỗi tuần',
        monthly: 'Mỗi tháng',
        day: 'Ngày'
    }
},
turnGame: {
    label: {
        continue: 'Tiếp tục chuyển game',
        confirmTransfer: 'Xác nhận chuyển game',
        question: 'ạ?'
    }
},
withdraw: {
    label: {
        selectBank: 'Lựa chọn ngân hàng nhận tiền',
        selectBank1: 'Vui lòng cập nhật ngân hàng trước',
        selectBank2: 'Thẻ ngân hàng nhận tiền.',
            goWebsite: 'Chuyển đến cấp nhật ngân hàng trên trang web chính thức.',
            continue: 'tiếp tục rút tiền',
        confirmWithdraw: 'Xác nhận rút tiền',
        question: 'ạ?'
    },
    placeholder: {
        withdraw: 'Chỉ có thể nhập tối đa 2 số thập phân',
    }
},
turnLine: {
    label: {
        downlineName: 'Tên cấp dưới',
        select: 'Lựa chọn',
        goOn1: 'Tiếp tục chuyển cấp dưới',
        goOn2: 'Tiếp tục cộng tiền',
        selectDownName: 'Tìm cấp dưới',
        placeholder: 'Nhập tên truy cập cấp dưới',
        lastWeek: 'Tuần gần nhất',
        lastMonth: 'Tháng gần nhất',
        lastThreeMonth: '3 tháng gần nhất',
        confirmMsg: 'Xác nhận cộng tiền ?',
            confirmMsg1: 'Xác nhận chuyển cấp dưới ?',
    }
},
finance: {
    label: {
        tips: "Tổng hoa hồng tuần và tháng hiện tại",
            budget: "Thu chi",
        generalAgencyCommission: "Hoa hồng đại lý thường",
        professionalAgencyCommission: "Hoa hồng đại lý chuyển",
        professional: "Đại lý chuyên"
    }
},
transactionRecord: {
    label: {
        requestID: "ID yêu cầu",
        reviewing: "Đang xử lý",
        preTransactionBalance: "Số tiền trước giao dịch",
        currentBalance: "Số dư hiện tại",
        currencySuffix: "VND",
            date: 'Thời gian',
        allTypes: 'Tất cả loại',
        waiting: 'Đợi chờ',
        paying: 'Chờ chuyển tiền'
    },
    placeholder: {
        requestID: "Nhập ID yêu cầu",
        activityType: 'Loại giao dịch',
    }
},
material: {
    label: {
        mobileQRCode: "Quet mã QR qua điện thoại",
        url: "Tên miền",
        copyURL: "Bấm để sao chép URL",
        download: "Bấm để download",
        image: "Hình ảnh",
        tweet: "tweet",
        video: "Video",
        audio: "Âm thanh",
        file: "Tập tin",
    },
    placeholder: {
        materialName: "Tên tài nguyên",
        selectGameType: 'Lựa chọn loại game',
        selectMaterialType: 'Loại tài nguyên',
        selectChannel: 'Chọn nguồn'
    }
},
tools: {
    label: {
        forward: "Chuyển tiếp tất cả",
        marketing: "Hệ thống sales",
        groupControl: "Hệ thống quản lý nhóm"
    }
},
promotion: {
    label: {
        historyOverview: 'Tổng quan lịch sử',
        domainName: 'Tên miền',
        initialDepositPeople: 'Số người gửi tiền lần đầu',
        registeredPeople: 'Số người đăng ký',
        initialDepositAmount: "Số tiền gửi lần đầu.",
    }
},
commission: {
    label: {
        firstAgentLevelDetail: "Chi tiếp đóng góp của thành viên cấp 1",
        secondAgentLevelDetail: "Chi tiếp đóng góp của thành viên cấp 2",
        thirdAgentLevelDetail: "Chi tiếp đóng góp của thành viên cấp 3",
        commissionContribution: "Số tiền đóng góp",
        modifyProfit: "Điều chỉnh tiền thắng",
        case1: "Phương án 1",
        case2: "Phương án 2",
        firstEffectiveCustNum: "Số thành viên hoạt động trực tiếp",
        agentCommission: "Dự toán hoa hồng hiện tại",
        lastAgentCommission: "Nợ trước",
        accountsPayable: "Dự toán số tiền ghi có",
        firstCommission: "Thành viên cấp 1 đóng góp",
        secondCommission: "Thành viên cấp 2 đóng góp",
        thirdCommission: "Thành viên cấp 3 đóng góp",
        viewDetails: 'Xem chi tiết',
        gameRatioDetails: 'Tỷ lệ hoa hồng chia theo game',
        lastLoss: "Lợi nhuận trước"
    }
},
management: {
    label: {
        totalBet: "Tổng doanh thu cược",
        totalWinLose: 'Tổng thắng thua',
        memberAccount: "Thống kê số lượng mở tài khoản",
        rate: "Tỷ lệ chiếm",
        similarMethodRatio: 'So sánh phương án cùng loại',
        increaseRate: 'Tỷ lệ tăng trưởng',
        increaseAmount: 'Số tiền tăng trưởng'
    }
},
dynamic: {
    label: {
        bet: "Đặt cược",
        profit: "Tiền thắng",
        totalLargeBets: "Tổng tiền đặt cược với số tiền lớn",
        totalLargeProfit: "Tổng tiền thắng cược với số tiền lớn",
    },
    customize: {
        thisWeek: 'Tuần hiện tại lần thứ',
        timesLogin: 'Lần đăng nhập',
        thisIsYour: 'Đây là lần thứ',
        member: 'thành viên'
    }
},
statistics: {
    label: {
        count: "số lần",
        rangeDate: "bắt đầu",
        withdrawCount: "Số lần rút tiền",
        depositCount: "Số lần gửi tiền"
    }
},
profit: {
    label: {
        gameKindAll: "Tất cả loại game",
        platformAll: "Tất cả nhà cung cấp",
        platform: "Nhà cung cấp",
        winLoseValue: "Giá trị thắng thua"
    }
},
myNav: {
    label: {
        home: "Trang chủ",
        proManagement: "Quản lý đại lý chuyên",
        proCurrentCommission: "Hoa hồng hiện tại đại lý chuyên",
        proCommissionProposal: "Lệnh thanh toán hoa hồng đại lý chuyên",
        finance: "Tài chính của tôi",
        commissionProposal: "Lệnh thanh toán hoa hồng",
        management: "Quản lý thành viên",
        dynamic: "Trạng thái thành viên",
        statistics: "Thống kê thành viên",
        information: "Thông tin thành viên",
        proDownLine: "Cấp dưới đại lý chuyên",
        promotion: "Quảng bá",
        links: "Tên miền quảng bá",
        material: "Tài nguyên quảng bá",
        tools: "Công cụ quảng bá",
        memberWinLose: "Thành viên thắng thua",
        proProportionProposal: "Lệnh thanh toán chia lợi nhuận cấp dưới",
        proProportionCurrentCommission: "Hoa hồng hiện tại của cấp dưới",
        commissionData: "Dữ liệu",
        proCommissionData: "Dữ liệu của Hội đồng"
    }
},
information: {
    label: {
        selectStarLevel: 'Lựa chọn cấp sao',
        selectValidID: 'Lựa chọn đánh dấu',
        downlineHierarchy: 'Tầng lớp cấp dưới',
        enabled: 'Kích hoạt',
        disabled: 'Vô hiệu',
        custRate: 'Tỷ lệ chia lợi nhuận',
        level: 'Cấp độ',
        setCustRate: 'Cài đặt tỷ lệ chia lợi nhuận',
        allGame: 'Tất cả game',
        details: 'Chi tiết Cấp dưới đóng góp'
    },
    placeholder: {
        username: 'Nhập tên truy cập',
        registeredSource: 'Nhập nguồn đăng ký',
        downLineParent: 'Tài khoản cấp trên'
    }
},
proDownline: {
    label: {
        numDirectReferrals: 'Số lượng thành viên trực tiếp và cấp dưới',
        commissionStatus: 'Trạng thái hoa hồng'
    }
}
}
