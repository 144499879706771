import Vue from 'vue';
import * as myutil from './api/myutil';
import * as api from "@/api/api";
import VueI18n from 'vue-i18n';
import i18n_CN from './api/i18n_CN';
import i18n_EN from './api/i18n_EN';
import i18n_VN from './api/i18n_VI';
import store from './store';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'zh-cn',
  messages: {
    "en": i18n_EN,
    'zh-cn': i18n_CN,
    "vi": i18n_VN
  }
});

Vue.filter("toFixed2", (value) => {
  if(!value) return 0;
  return Number(value).toFixed(2)
});

Vue.filter("formatLoginName", myutil.formatLoginName);

Vue.filter("twoDig", myutil.twoDig);

Vue.filter("datetimeToDate", function (value) {
  if (value) {
    return value.substring(0, 10);
  }
  return value;
});

Vue.filter("formatDatetime", function (value) {
  if (value) {
    return value.substring(0, 19);
  }
  return value;
});

Vue.filter("numFilter", function (value) {
  if (value) {
    let transformVal = Number(value).toFixed(3);

    let realVal = transformVal.substring(0, transformVal.length - 1);

    return Number(realVal);
  }
  return value;
});

Vue.filter("monthDay", myutil.toMonthAndDay);

Vue.filter("calIncomeCommission", myutil.calIncomeCommission);

Vue.filter("contractMinEffectiveCustNum", myutil.contractMinEffectiveCustNum);

Vue.filter("floatFormat", myutil.floatFormat);
Vue.filter("floatFormatCurrency", myutil.floatFormatCurrency);
Vue.filter("floatFormatCurrencyUnit", myutil.floatFormatCurrencyUnit);

Vue.filter("floatFormatSubFix", myutil.floatFormatSubFix);

Vue.filter("digitalFormat", function (value) {
  return myutil.floatFormat(value);
});

Vue.filter("cycleType", function (value) {
  let obj = {
    "1": i18n.t('public.label.cycle.day'),
    "2": i18n.t('public.label.cycle.week'),
    "3": i18n.t('public.label.cycle.month'),
    "4": i18n.t('public.label.cycle.halfMonth'),
  };
  return obj[value]
});

Vue.filter("standard", function (value) {
  let obj = {
    "0": i18n.t('public.status.noStandard'),
    "1": i18n.t('public.status.isStandard')
  };
  return obj[value]
});

Vue.filter("commissionLabel", function (value) {
  let obj = {
    "0": i18n.t('home.label.profit'),
    "1": i18n.t('home.label.bettingAmount')
  };
  return obj[value]
});

Vue.filter("commissionType", function (value) {
  let obj = {
    "0": i18n.t('public.label.commissionType0'),
    "1": i18n.t('public.label.commissionType1')
  };
  return obj[value]
});

Vue.filter("status", function (value) {
  let obj = {
    "0": i18n.t('public.status.unrecorded'),
    "1": i18n.t('public.status.recorded'),
    "2": i18n.t('public.status.reject'),
  };
  return obj[value]
});

Vue.filter("dynamicTypeTitle", function (value) {
  let obj = {
    "1": i18n.t('home.label.newEstablishAccount'),
    "2": i18n.t('public.label.transaction.deposit'),
    "3": i18n.t('public.label.transaction.withdraw'),
    "4": i18n.t('public.button.login'),
    "5": i18n.t('home.label.bigBet'),
    "6": i18n.t('public.label.bigProfit'),
  };
  return obj[value]
});

Vue.filter("dynamicType", function (value) {
  let obj = {
    "1": i18n.t('public.label.time.registerTime'),
    "2": i18n.t('public.label.time.depositTime'),
    "3": i18n.t('public.label.time.withdrawTime'),
    "4": i18n.t('public.label.time.loginTime'),
    "5": i18n.t('public.label.time.betTime'),
    "6": i18n.t('public.label.time.profitTime'),
  };
  return obj[value]
});

Vue.filter("flag", function (value) {
  let obj = {
    "2": i18n.t('public.status.settled'),
    "4": i18n.t('public.status.settled'),
    "-3": i18n.t('public.status.reject'),
    "-4": i18n.t('public.status.reject'),
    "-5": i18n.t('public.status.reject'),
  };
  return obj[value] ? obj[value] : i18n.t('public.status.inSettlement')
});

Vue.filter("transCode", function (value) {
  let obj = {
    "112706": i18n.t('public.label.transaction.commissionsEarned'),
    "112707": i18n.t('public.label.transaction.proAgentCommissionsEarned'),
    "113507": i18n.t('public.label.transaction.cashToCommission'),
    "113508": i18n.t('public.label.transaction.cancelCashToCommission'),
    "113511": i18n.t('public.label.transaction.cancelWithdrawal'),
    "113505": i18n.t('public.label.transaction.commissionWithdrawals'),
    "113506": i18n.t('public.label.transaction.commissionToDownline'),
    "113504": i18n.t('public.label.transaction.commissionToGame'),
    "113509": i18n.t('public.label.transaction.gameCurrencyPoints'),
    "113512": i18n.t('public.label.transaction.modifyCommission'),
    "113514": i18n.t('public.label.transaction.onlineTransfer'),
  };
  return obj[value]
});
Vue.filter("platFormId", function (value) {
  return api.platform[value]
});

Vue.filter("gameKind", function (value) {
  let obj = {
    "1": i18n.t('public.label.game.sports'),
    "2": i18n.t('public.label.game.eBets'),
    "3": i18n.t('public.label.game.realPeople'),
    "5": i18n.t('public.label.game.videoGame'),
    "8": i18n.t('public.label.game.fishing'),
    "9": i18n.t('public.label.game.eSports'),
    "12": i18n.t('public.label.game.lottery'),
    "15": i18n.t('public.label.game.threeD'),
    "999": i18n.t('information.label.allGame')
  };
  return obj[value]
});

Vue.filter("priorityLevel", function (value) {
  let obj = {
    "-6": i18n.t('public.priorityLevel.discount'),
    "-5": i18n.t('public.priorityLevel.arbitrage'),
    "-4": i18n.t('public.priorityLevel.test'),
    "-3": i18n.t('public.priorityLevel.offline'),
    "-2": i18n.t('public.priorityLevel.attention'),
    "-1": i18n.t('public.priorityLevel.blacklist'),
    "0": i18n.t('public.priorityLevel.ordinary'),
    "1": "VIP",
    "2": i18n.t('public.priorityLevel.whiteList')
  }
  console.log(obj[value])
  return obj[value] ? obj[value] : "未知"
})

Vue.filter('NumFormat', function (value) {
  if (!value) return ' '
  var intPart = Number(value).toFixed(2) // 获取整数部分
  var intPartFormat = intPart.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

 
export default {};


/*  flag
* 0，待审核，恢复一审，       审核中
* 1，审核中，                 审核中
* 6，修改额度，
* 5，一审通过，               审核中
* -4，一审拒绝，
* -3，二审拒绝，
* 2，二审通过（待结算）， (未入账)    成功
* 4，已结算，             (已入账)  成功
* -2，已删除，
* 9，预估,
* -5，已过期
* */

/*转游戏，转下线，佣金入账       没有审核中， 一定是成功*/
/*取款 use flag*/

/*兑换类型，1：后续转游戏币 2：后续取款 3:转给下线*/
