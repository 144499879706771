<template>
  <!-- 站内信强弹 -->
  <div class="letter-dialog">
    <el-dialog
      v-if="show"
      center
      title="站内信"
      width="540px"
      :visible="true"
      @close="onClose"
      style="height: 100%"
      class="msg-detail"
    >
      <div class="content">
        <!-- v-model="activeNames" @change="handleChange" -->
        <el-collapse v-model="chosenum" @change="handleChange" accordion>
          <el-collapse-item
            v-for="(item, index) in messageList"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <span class="title_span">{{ item.property1 }}</span>
              <span class="time_text">{{ item.createdDate }}</span>
            </template>
            <div>{{ item.property2 }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div slot="footer">
        <el-button @click="onClose" type="primary" class="btn-submit"
          >我知道了</el-button
        >
        <el-button @click="onConfirm" type="primary" class="btn-submit"
          >查看更多</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { letterQuery, letterView ,configAppurlApi} from '@/services/api/user';
import { mapGetters } from "vuex";
import * as api from "../../api/api.js";
import { storeCommit } from '../../api/myutil.js';
export default {
  name: "letter-dialog",
  data() {
    return {
      show: false,
      letterData: {},
      chosenum: 0,
      messageList: [],
    };
  },
  computed: {
    // ...mapGetters(['isLogin','userName'])
  },
  methods: {
     updateUnreadMessage(){
      const arr = this.messageList.filter((v) => v.flag == 0);
      storeCommit("unreadMessage", arr);
    },
    // 获取 强弹
    getLetterDialog() {
      let params = {
        pageSize: 5,
        pageNum: 1,
        flag: "0",
      };
      api.queryMsg(params,  (res)=> {
        let message = res.data;
        let content = message.data.content;
        if (!message.fail && content.length > 0) {
          this.messageList = content;
          storeCommit("unreadMessage", this.messageList);
          this.show = true;
        }
      });
    },
    async onConfirm() {
      this.show = false;
      this.modifyMsgDetail();
      // 导过去并由该页刷新列表与未读数量
      this.$emit("open");
    },
    async onClose() {
      this.show = false;
      this.modifyMsgDetail();
    },
    // 点击站内信改变是否已读
    handleChange(val) {
      this.chosenum = val;
      const item = this.messageList[this.chosenum]
      if (item && item.flag == 0) {
        this.$set(this.messageList[this.chosenum], "flag", 1);
        this.modifyMsgDetail();
      }
    },
    modifyMsgDetail() {
      let params = {
        id: this.messageList[this.chosenum].id,
        flag: 1,
      };
      api.modifyMsg(params,  (res)=> {
        let message = res.data;
        this.updateUnreadMessage()
      });
    },
  },
  created() {
    this.getLetterDialog();
  },
};
</script>
<style>
.msg-detail .el-dialog__footer {
  background: #ffffff !important;
}
.msg-detail .content {
  width: 100%;
  border: none;
}
.el-collapse {
  border-top: none !important;
  border-bottom: none !important;
}
.el-collapse-item {
  margin-top: 8px;
}
.el-collapse-item .el-collapse-item__header {
  border-bottom: none !important;
  background-color: #ebebeb;
}
.is-active .el-collapse-item__header {
  background-color: #f8f8f8 !important;
}
.content .el-collapse-item__wrap {
  background-color: #f8f8f8 !important;
  border-bottom: none !important;
  padding: 0 40px 0 20px;
}
.el-collapse-item {
  background: #f8f8f8 !important;
}
.content .el-collapse-item__header {
  position: relative;
  padding-left: 20px;
}
.title_span {
  font-size: 12px;
  color: #3875e2;
}
.time_text {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
