import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
    ...zhLocale,
    public: {
        label: {
            currencyType:"货币类型",
            loginName: "会员账号",
            password: "密码",
            transactionPassword:"支付密码",
            realName: "真实姓名",
            verificationCode: '验证码',
            remarks: "备注",
            submit: "提交",
            done: "完成",
            notice: '提示',
            colon: "：",
            comma: '，',
            period: "。",
            openParenthesis: '（',
            closeParenthesis: '）',
            commissionType0: "占成",
            commissionType1: "抽佣",
            inputUsername: '请输入会员账号',
            loading: '加载中',
            noData: '暂无数据',
            status: '状态',
            people: '人',
            amount: '金额',
            amount2: '充值金额',
            parentName: "上线账号",
            systemAccountOpening: "系统开户",
            generalizationCode: "推广码",
            exclusiveDomainName: "专属域名",
            bigProfit: '大额盈利',
            transaction: {
                deposit: "存款",
                withdraw: "取款",
                commissionGame: "转游戏",
                commissionDownline: "转下线",
                cashToCommission: "现金转佣金",
                commissionToGame: '佣金转游戏',
                commissionWithdrawals: '佣金取款',
                commissionToDownline: '佣金转下线',
                cancelCashToCommission: '取消现金转佣金',
                gameCurrencyPoints: '给下线充值',
                cancelWithdrawal: '取消取款',
                commissionsEarned: '佣金入账',
                proAgentCommissionsEarned: '专业合作商佣金入账',
                modifyCommission: '佣金手动修改',
                onlineTransfer: "上线转入"
            },
            time: {
                registerTime: '开户时间',
                depositTime: '存款时间',
                withdrawTime: '取款时间',
                loginTime: '登录时间',
                betTime: '投注时间',
                profitTime: '盈利时间',
                lastLoginTime: '最后登录时间'
            },
            registrationDate: '注册时间',
            registerFromType: '注册终端',
            startDate: '开始日期',
            endDate: '结束日期',
            registStartDate: '注册开始日期',
            registEndDate: '注册结束日期',
            separator: '至',
            search: '查询',
            lastSevenDays: '最近7天',
            lastOneMonth: '最近30天',
            commissionDetail: "佣金详情",
            memberDetails: "会员详情",
            index: "序号",
            gameKind: "游戏类型",
            firstLine: "直线会员",
            oneLine:"一级直线会员",
            secondLine: "二级直线会员",
            thirdLine: "三级直线会员",
            all: '全部',
            others: "其他",
            cycle: {
                cycleType: "周期",
                day: "日结",
                week: "周结",
                month: "月结",
                halfMonth: "半月结",
            },
            game: {
                sports: "体育",
                eBets: "电投",
                realPeople: "真人",
                videoGame: "电子游戏",
                mahjong: "麻将",
                battle: "对战/德州扑克",
                fishing: "捕鱼游戏",
                eSports: "电竞",
                lottery: "彩票",
                threeD: "3D",
                poker: "牛牛",
            },
            channel: {
                qq: "QQ",
                wechat: "微信",
                facebook: "脸书",
            },
            starLevel: '星级',
            validID: '有效标识',
            registeredSource: '注册来源',
            registerIp: '注册IP'
        },
        status: {
            settled: "已结算",
            unsettled: "未结算",
            recorded: "已入账",
            unrecorded: "未入账",
            enabled: '已启用',
            disabled: '已禁用',
            standard: "是否达标",
            isStandard: "已达标",
            noStandard: "未达标",
            reject: "拒绝",
            inSettlement: '结算中',
            badDebt: '坏账'
        },
        button: {
            login: "登录",
            confirm: "确认",
            cancel: '取消',
            back: "返回",
            settlement: "结算",
            selectAll: '全选'
        },
        msg: {
            error: {
                fillCorrectInfo: '请按提示填完信息！',
                inputPassword: '请输入密码',
                confirmPassword: '请再次输入密码',
                pwdNotMatch: '两次输入密码不一致！',
                copy: '复制失败，请手动复制！',
                inputAmount: '请输入金额',
                validAmount: '金额只能输入数字，小数点后最多只能有两位',
                validAmountBTC: '金额只能输入数字，小数点后最多只能有四位',
                validBalance: '金额不能超过账号余额',
                passwordError: '密码错误，请重新输入！',
                login: '登陆超时，请重新登陆',
                unknown: '未知错误请重试',
                verificationCode: '验证码刷新失败，请清理浏览器缓存',
                networkTimeout: '网络连接超时',
                requestedAddress: '找不到对应的请求地址，请检查！',
                appToken: 'app_token 无效',
                remarks: '请输入备注'
            },
            success: {
                copy: '复制成功！',
                modify: '修改成功！',
                settled: '结算成功！'
            },
            pwd: {
                input: '请输入',
                numbers: '位数字',
                or: '或',
                and: '和',
                characters: '字母'
            },
            range: {
                between: '金额大小必须在',
                to: '到',
                scope: '范围内'
            }
        },
        priorityLevel: {
            discount: "打优惠",
            arbitrage: "套利客",
            test: "测试",
            offline: "下线",
            attention: "关注",
            blacklist: "黑名单",
            ordinary: "普通",
            whiteList: "白名单"
        }
    },
    addMember: {
        label: {
            requiredInformation: "必填信息",
            name: "真实姓名",
            confirmPassword: "确认密码",
            telPhoneNum: "手机号码",
            email: "邮箱",
            selectiveInformation: "选填信息",
            sex: "性别",
            male: "男",
            female: "女",
            continueAdd: "继续新增",
            addNewMember: '确定要添加会员？'
        },
        placeholder: {
            remarks: '最多只能输入20个字符'
        },
        msg: {
            error: {
                qqEmail: '请填写正确的QQ邮箱',
                inputValidPhone: '请输入正确的手机号',
                accountExists: '账号名已存在',
                emailAddress: '请填写邮箱',
                emailFormat: '邮箱格式错误',
                realName: '请填写真实姓名',
                mandatory: '这里是必填项'
            },
            success: {
                addNewMember: '新增会员成功'
            }
        }
    },
    modifyPassword: {
        label: {
            oldPassword: "旧密码",
            newPassword: "新密码",
            rePassword: "确认新密码",
            confirmChangePwd: '请确认是否设置此交易密码？'
        },
        button: {
            loginAgain: '重新登陆',
            later: '稍后再说'
        },
        msg: {
            error: {
                oldPassword: '旧密码错误',
                changePwd: '修改失败请重试！'
            },
            success: {
                changePwd: '修改密码成功，是否退出重新登陆？'
            }
        }
    },
    app: {
        label: {
            modifyPassword: "修改登录密码",
            modifyTransactionPassword: "修改交易密码",
            logout: "退出",
            logoutAccount: "退出账号",
            balance: "余额",
            transactionRecord: "交易记录",
            download: "操作手册下载",
            language: "多语言",
            message: "消息",
            customerService: "客服",
            contactNumber: "电话号码",
            contact: "联系方式",
            applyCallback: "回拨申请",
            messageTitle: "站内信",
            messageTitleDetail: "详情",
            freeCallBackTitle: "免费电话回拨",
            getCommission: "获取高额佣金方案",
            read: '已读',
            unread: '未读',
            msgDetailTitle: '标题',
            msgDetailContent: '内容',
            title: '主题',
            date: '日期'
        },
        button: {
            delete: '删除',
            deleteRecord: '删除记录'
        },
        msg: {
            error: {
                contactNumber: '请输入长度在11到12之间的字符串'
            },
            success: {
                delete: '删除成功！',
                callbackSent: '请稍等，一会将有客服联系您！',
                getCommissionSent: '您的联系方式已提交，我们会第一时间联系您'
            }
        }
    },
    login: {
        label: {
            title: "请输入账号和密码",
            loginName: "账号",
            contactCS: "联系客服",
            rights: "AG旗下品牌 © 2017 环亚娱乐版权所有 all rights reserved",
            productName: "凯时合作伙伴",
            welcome: "欢迎使用 乐橙代理系统",
            c07_welcome: "欢迎使用",
            a01_name: "共赢天下",
            a04_name: "财富伙伴",
            c07_name: "",
            b07_welcome: "欢迎使用 利来电游共赢系统"
        },
        msg: {
            error: {
                matchUserPwd: '用户名和密码不匹配'
            },
            success: {
                login: '登录成功'
            }
        }
    },
    links: {
        label: {
            tips: "您还可以",
            addMember: "新增会员",
            exclusivePromotionDomainName: "专属推广域名",
            identificationCode: "识别码",
            replicationLink: "复制链接",
            promotionDomainName: "全站推广域名",
            qrCode: "二维码"
        }
    },
    home: {
        label: {
            calculationDescription: "佣金计算说明",
            validMember: "有效会员",
            commission: "佣金",
            professionalAgent: "专业合作商",
            ordinaryAgentOption1: "普通合作商(方案一)",
            ordinaryAgentOption2: "普通合作商(方案二)",
            ordinaryAgentMonth: "普通合作商（月结）",
            ordinaryAgentHalfMonth: "普通合作商（半月结）",
            ordinaryAgentWeek: "普通合作商（周结）",
            ordinaryAgentDay: "普通合作商（日结）",
            centralLink: "环比上期",
            establishAccount: "开户",
            newEstablishAccount: "新开户",
            newDepositNum: "新开户存款人数",
            depositRatio: "新开户首存比",
            newMemberDeposit: "新开户存款总额",
            newMemberTotalDeposit: "新开户存款总额",
            totalDeposit: "存款总额",
            winLose: "输赢",
            totalWinLose: "会员总输赢",
            bigBet: "大额投注",
            bigBetNum: "大额投注次数",
            totalWithdraw: "取款总额",
            totalContribution: "会员总贡献",
            firstContribution: "直线会员贡献",
            secondContribution: "二线会员贡献",
            thirdContribution: "三线会员贡献",
            goCommissionProposal: "当期佣金详情",
            dynamic: '会员动态',
            currentCommissionBudget: "当期佣金预算",
            currentCommission: "当期佣金",
            historyCommission:"历史佣金",
            netProfit: "净盈利",
            commissionRate: "佣金比例",
            reportProfit: "报表盈利",
            platformCost: "平台成本",
            capitalCost: "资金成本",
            rebate: "洗码",
            discount: "优惠",
            exchangeRate:"当日汇率",
            date:"汇率日期",
            remark:"备注",
            profitRevision: "盈利修正",
            promotionCost: "推广成本",
            otherCost: "其他成本",
            income: "收入",
            bettingAmount: "洗码投注额",
            contribution: "会员贡献",
            profit: "净利润",
            level: "档次",
            bet: "投注额",
            current: "当期",
            and: "并且",
            or: "或者",
            game: '游戏',
            amortizedCostRatio: "公摊成本比例",
            commissionRatio: "返佣比例",
            commissionFormula: "佣金计算公式",
            upgradeCondition: "升档条件",
            commissionRules: "佣金规则",
            priorityLevel: "优先级"
        },
        msg: {
            error: {
                currentCommissionDataError: '当期佣金数据异常',
                tryAgain: '请重试'
            }
        },
        condition: {
            standard: "达标",
            msg: "达标后按照最高的达标档位计算佣金"
        }
    },
    firstLogin: {
        label: {
            baseInfo: "基本信息",
            modifyPlan: "修改方案",
            settlementCycle: "结算周期",
            hello: "您好",
            welcome: "欢迎来到",
            proAgentMethod: '专业合作商方案',
            generalMethod: '普通合作商方案',
        },
        cycle: {
            weekly: '每周',
            monthly: '每月',
            day: '号'
        }
    },
    turnGame: {
        label: {
            continue: '继续转游戏',
            confirmTransfer: '确定要转游戏',
            question: '吗？'
        }
    },
    withdraw: {
        label: {
            selectBank: '请选择收款银行',
            selectBank1: '请先绑定银行卡',
            selectBank2: '收款银行卡',
            goWebsite: '去官网绑定银行卡',
            continue: '继续取款',
            confirmWithdraw: '确定要取款',
            question: '吗？'
        },
        placeholder: {
            withdraw: '小数点后最多只能输入两位',
        }
    },
    turnLine: {
        label: {
            downlineName: '下线名',
            downlineName2: '下线游戏帐号',
            select: '选择',
            goOn1: '继续转下线',
            goOn2: '继续上分',
            selectDownName: '查找下线',
            placeholder: '请输入下线账号',
            lastWeek: '最近一周',
            lastMonth: '最近一个月',
            lastThreeMonth: '最近三个月',
            confirmMsg: '确定要上分吗？',
            confirmMsg1: '确定要转下线吗？',
        }
    },
    finance: {
        label: {
            tips: "（当期周结佣金和月结佣金的总和）",
            budget: "收支",
            generalAgencyCommission: "普通合作商佣金",
            professionalAgencyCommission: "专业合作商佣金",
            professional: "专业合作商"
        }
    },
    transactionRecord: {
        label: {
            requestID: "请求 ID",
            reviewing: "审核中",
            preTransactionBalance: "交易前余额",
            currentBalance: "现余额",
            currencySuffix: "",
            date: '时间',
            allTypes: '全部类型',
            waiting: '等待',
            paying: '待付款'
        },
        placeholder: {
            requestID: "请输入请求ID",
            activityType: '业务类型',
        }
    },
    material: {
        label: {
            mobileQRCode: "手机扫描二维码",
            url: "链接",
            copyURL: "点击复制URL",
            download: "点击下载",
            image: "图片",
            tweet: "推文",
            video: "视频",
            audio: "音频",
            file: "文件",
        },
        placeholder: {
            materialName: "素材名称",
            selectGameType: '游戏类型选择',
            selectMaterialType: '素材类型',
            selectChannel: '发布渠道'
        }
    },
    tools: {
        label: {
            forward: "一键转发",
            marketing: "营销系统",
            groupControl: "群控系统"
        }
    },
    promotion: {
        label: {
            historyOverview: '历史总览',
            domainName: '域名',
            initialDepositPeople: '首存人数',
            registeredPeople: '注册人数',
            initialDepositAmount: "首存金额",
        }
    },
    commission: {
        label: {
            firstAgentLevelDetail: "一级会员贡献详情",
            secondAgentLevelDetail: "二级会员贡献详情",
            thirdAgentLevelDetail: "三级会员贡献详情",
            commissionContribution: "贡献金",
            modifyProfit: "修正盈利",
            case1: "方案一",
            case2: "方案二",
            firstEffectiveCustNum: "直线有效会员数",
            agentCommission: "预估当期佣金",
            lastAgentCommission: "往期欠款",
            accountsPayable: "预估可入账金额",
            firstCommission: "一级会员贡献",
            secondCommission: "二级会员贡献",
            thirdCommission: "三级会员贡献",
            viewDetails: '查看详情',
            gameRatioDetails: '分游戏佣金比例详情',
            lastLoss: "往期负盈利"
        }
    },
    management: {
        label: {
            totalBet: "有效投注额",
            totalWinLose: '总输赢',
            memberAccount: "开户统计",
            rate: "所占比例",
            similarMethodRatio: '同类方案环比',
            increaseRate: '增额比例',
            increaseAmount: '增额金额'
        }
    },
    dynamic: {
        label: {
            bet: "投注",
            profit: "盈利",
            totalLargeBets: "大额投注总额",
            totalLargeProfit: "大额盈利总额",
        },
        customize: {
            thisWeek: '本周第',
            timesLogin: '次登录',
            thisIsYour: '这是您第',
            member: '个会员'
        }
    },
    statistics: {
        label: {
            count: "次数",
            rangeDate: "起始时间",
            withdrawCount: "取款次数",
            depositCount: "存款次数"
        }
    },
    profit: {
        label: {
            gameKindAll: "全部游戏种类",
            platformAll: "全部游戏平台",
            platform: "平台",
            winLoseValue: "输赢值"
        }
    },
    myNav: {
        label: {
            home: "首页",
            proManagement: "专业合作商管理",
            proCurrentCommission: "专业合作商当期佣金",
            proCommissionProposal: "专业合作商佣金提案",
            finance: "我的财务",
            commissionProposal: "佣金提案",
            management: "会员管理",
            dynamic: "会员动态",
            statistics: "会员统计",
            information: "会员信息",
            proDownLine: "专业合作商下线",
            promotion: "推广",
            systemManagement:"系统管理",
            commissionSettings:"抽佣设置",
            occupancySettings:"占成设置",
            links: "推广链接",
            material: "推广素材",
            tools: "推广小工具",
            memberWinLose: "会员输赢",
            proProportionProposal: "下线分成提案",
            proProportionCurrentCommission: "下线当期佣金",
            commissionData: "佣金数据",
            proCommissionData: "专业合作商佣金"
        }
    },
    information: {
        label: {
            selectStarLevel: '请选择星级',
            selectValidID: '请选择有效标识',
            downlineHierarchy: '下线层级',
            enabled: '启用',
            disabled: '禁用',
            custRate: '分成比例',
            level: '档',
            setCustRate: '分成比例配置',
            allGame: '全部游戏',
            details: '下线贡献详情'
        },
        placeholder: {
            username: '请输入会员账号',
            registeredSource: '请输入注册来源',
            downLineParent: '上线账号'
        }
    },
    proDownline: {
        label: {
            numDirectReferrals: '直线下线数量',
            commissionStatus: '佣金状态'
        }
    }
}
