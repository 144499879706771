<template>
  <div id="LoadingPage">
    <!--logo-->
    <div class="logo"></div>
    <!--进度条-->
    <div class="progress-bar">
      <div class="bar-bg">
        <div class="bar-ft" :style="{width:percentTxt}"></div>
      </div>
      <div class="percent" v-html="percentTxt"></div>
    </div>
    <!--文字-->
    <div class="p-txt" v-html="tipsTxt"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
  data () {
    return {
      percent: 0,
      percentList: [
        { percent: 30 },
        { percent: 50 },
        { percent: 70 },
        { percent: 90 },
        { percent: 99 }
      ],
      timer: null
    }
  },
  props: {
    // 提示文字
    tipsTxt: {
      type: String,
      default: ''
    },
    speed: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    }
  },
  methods: {
    // 开始倒数
    loadingStart (index = 0, speed = 120) {
      const self = this
      self.percent = index
      self.timer = setInterval(() => {
        if (self.percent < 99) {
          self.percent += 1
          // 如果 speed 变动，则停止目前timer，新创一个
          if (this.speed > 0 && this.speed < 1) {
            clearInterval(this.timer)
            self.loadingStart(100, this.speed) // 直接跳到100%
          }
          if (this.speed > 1 && this.speed < speed) {
            clearInterval(this.timer)
            self.loadingStart(self.percent, this.speed) // 加速
          }
          this.$emit('loadingTimer', self.percent)
        } else {
          self.stopLoading()
        }
        // 按比例进行的跑法
        // if (self.percentList[index] && self.percentList[index].percent < self.percent) {
        //   clearInterval(self.timer)
        //   self.loadingStart(++index, speed + 50)
        //   if (self.percentList.length - 1 < index) {
        //     self.stopLoading()
        //   }
        // }
      }, speed)
    },
    stopLoading () {
      clearInterval(this.timer)
      this.$emit('loadingTimeOut')
    }
  },
  mounted () {
    this.loadingStart()
  },
  computed: {
    percentTxt () {
      return this.percent + '%'
    }
  },
  beforeDestroy () {
    this.stopLoading()
  }
}
</script>

<style lang="less" scoped>
  #LoadingPage {
    width: 600px;
    text-align: center;
    margin: auto;
    height: 100%;
    padding: 0 30px;
    color: #4a4a4a;
    .logo{
      // background: url('../../assets/img/login/loading-page-logox2.png');
      background: url("../../assets/img/svg/Logo_lite.svg") no-repeat;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 260px;

      margin-top: 200px;
      margin-bottom: 80px;
    }
    .progress-bar{
      margin: 0 0 80px;
      .bar-bg{
        margin: 16px auto;
        height: 18px;
        background: #d0d0d0;
        border-radius: 10px;
        position: relative;
      }
      .bar-ft{
        position: absolute;
        left: 0;
        height: 18px;
        background-image: linear-gradient(90deg, #135CDF 13%, #5587E2 60%);
        border-radius: 10px;
      }
      .percent{
        font-size: 20px;
      }
    }
    .p-txt{
      font-size: 15px;
      letter-spacing: 2px;
    }
  }
</style>
