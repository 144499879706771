import Vue from 'vue';
import VueRouter from 'vue-router';
import EventBus from 'eventbusjs';
import store from './store';
import Vuex from "vuex";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
let isGeneral = store.state.isGeneral;
Vue.use(VueRouter);

// const Start = () => import("./");
const Login = () => import("./components/public/login.vue");
const AddMember = () => import("./components/home/addMember.vue");
const Home = () => import("./components/home/home.vue");
const ModifyPassword = () => import("./components/home/modifyPassword.vue");
const ModifyTransactionPassword = () => import("./components/home/modifyTransactionPassword.vue");
const TurnGame = () => import("./components/home/turnGame.vue");
const TurnLine = () => import("./components/home/turnLine.vue");
const TransactionRecord = () => import("./components/home/transactionRecord.vue")
const Withdraw = () => import("./components/home/withdraw.vue");

const Promotion = () => import("./components/promotion/promotion.vue");
const Links = () => import("./components/promotion/links.vue");
const Material = () => import("./components/promotion/material.vue");
const Tools = () => import("./components/promotion/tools.vue");

const Dynamic = () => import("./components/member/dynamic.vue");
const Information = () => import("./components/member/information.vue");
const Management = () => import("./components/member/management.vue");
const Profit = () => import("./components/member/profit.vue");
const Statistics = () => import("./components/member/statistics.vue");
const ProDownLine = () => import("./components/member/proDownLine.vue");

const Finance = () => import("./components/finance/finance.vue");
const CommissionProposal = () => import("./components/finance/commissionProposal.vue");
const CurrentCommission = () => import("./components/finance/currentCommission.vue");
const CommissionHistory = () => import("./components/finance/commissionHistory.vue");
const ProCommissionHistory = () => import("./components/finance/proCommissionHistory.vue");

const ProManagement1 = () => import("./components/professionalAgent/divideManagement.vue");
const ProCurrentCommission1 = () => import("./components/finance/proProportionCurrentCommission.vue");
const ProCommissionProposal1 = () => import("./components/finance/proProportionProposal.vue");

// const ProManagement2 = () => import("./components/professionalAgent/proManagement.vue");
// const ProCurrentCommission2= () => import("./components/professionalAgent/proCurrentCommission.vue");
// const ProCommissionProposal2 = () => import("./components/professionalAgent/proCommissionProposal.vue");

const ProManagement = () => import("./components/professionalAgent/proManagement.vue");
const ProCurrentCommission = () => import("./components/professionalAgent/proCurrentCommission.vue");
const ProCommissionProposal = () => import("./components/professionalAgent/proCommissionProposal.vue");

// let ProManagement = null;
// let ProCurrentCommission = null;
// let ProCommissionProposal = null;
// if (isGeneral) {
//     console.log(1+'isGeneral');
//     ProManagement = () => import("./components/professionalAgent/proManagement.vue");
//     ProCurrentCommission = () => import("./components/professionalAgent/proCurrentCommission.vue");
//     ProCommissionProposal = () => import("./components/professionalAgent/proCommissionProposal.vue");
// } else {
//     console.log(0+'isGeneral');
//     ProManagement = () => import("./components/professionalAgent/divideManagement.vue");
//     ProCurrentCommission = () => import("./components/finance/proProportionCurrentCommission.vue");
//     ProCommissionProposal = () => import("./components/finance/proProportionProposal.vue");
// }
const ProProportionProposal = () => import("./components/professionalAgent/proProportionProposal.vue");
const ProProportionCurrentCommission = () => import("./components/professionalAgent/proProportionCurrentCommission.vue");
const SystemManagement = () => import("./components/systemManagement/systemManagement.vue");
const CommissionSetting = () => import("./components/systemManagement/commissionSetting.vue");
const Occupancy = () => import("./components/systemManagement/occupancy.vue");


let router = new VueRouter({
    routes: [
        // {name: 'start', path:'/', component: Start},
        {name: 'login', path: '/', component: Login},
        {name: 'addMember', path: '/addMember', component: AddMember},
        {name: 'home', path: '/home', component: Home},
        {name: 'modifyPassword', path: '/modifyPassword', component: ModifyPassword},
        {name: 'modifyTransactionPassword', path: '/modifyTransactionPassword', component: ModifyTransactionPassword},
        {name: 'turnLine', path: '/turnLine', component: TurnLine},
        {name: 'turnGame', path: '/turnGame', component: TurnGame},
        {name: 'transactionRecord', path: '/transactionRecord', component: TransactionRecord},
        {name: 'withdraw', path: '/withdraw', component: Withdraw},

        {name: 'promotion', path: '/promotion', component: Promotion},
        {name: 'links', path: '/links', component: Links},
        {name: 'material', path: '/material', component: Material},
        {name: 'tools', path: '/tools', component: Tools},

        {name: 'dynamic', path: '/dynamic', component: Dynamic},
        {name: 'information', path: '/information', component: Information},
        {name: 'management', path: '/management', component: Management},
        {name: 'profit', path: '/profit', component: Profit},
        {name: 'statistics', path: '/statistics', component: Statistics},
        {name: 'proDownLine', path: '/proDownLine', component: ProDownLine},

        {name: 'finance', path: '/finance', component: Finance},
        {name: 'commissionProposal', path: '/commissionProposal', component: CommissionProposal},
        {name: 'currentCommission', path: '/currentCommission', component: CurrentCommission},
        {name: 'commissionHistory', path: '/commissionHistory', component: CommissionHistory},
        {name: 'proCommissionHistory', path: '/proCommissionHistory', component: ProCommissionHistory},

        {name: 'proCommissionProposal', path: '/proCommissionProposal', component: ProCommissionProposal},
        {name: 'proCurrentCommission', path: '/proCurrentCommission', component: ProCurrentCommission},
        {name: 'proManagement', path: '/proManagement', component: ProManagement},
        {name: 'proProportionProposal', path: '/proProportionProposal', component: ProProportionProposal},
        {name: 'proProportionCurrentCommission', path: '/proProportionCurrentCommission', component: ProProportionCurrentCommission},
        {name: 'proCommissionProposal1', path: '/proCommissionProposal1', component: ProCommissionProposal1},
        {name: 'proCurrentCommission1', path: '/proCurrentCommission1', component: ProCurrentCommission1},
        {name: 'proManagement1', path: '/proManagement1', component: ProManagement1},
        {name: 'systemManagement', path: '/systemManagement', component: SystemManagement},
        {name: 'commissionSetting', path: '/commissionSetting', component: CommissionSetting},
        {name: 'occupancy', path: '/occupancy', component: Occupancy},
        
    ]
});

EventBus.addEventListener("toRelogin", function () {
    router.push({'name': 'login'});
});

export default router;
