<template>
  <div id="balance-lock-modal" v-if="isModalShow">
    <div class="mask"></div>
    <div class="message">
      <div class="header">温馨提示</div>
      <div class="body">
        <div class="content">
          尊敬的用户，系统对您的余额进行了锁定保护，请前往官网解锁！
        </div>
      </div>
      <div class="footer">
        <div class="confirm" @click="openZlUrl">前往官网解锁</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BalanceLockModal",
  data() {
    return {
      isModalShow: false,
      agentWebsites: ["j9top.io", "j9.io"], //轻量站、主站
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    isInnerAgent() {
      //是否特级代理
      return this.userInfo.isInnerAgent === 4;
    },
  },
  methods: {
    show() {
      this.isModalShow = true;
    },
    close() {
      this.isModalShow = false;
    },
    openZlUrl() {
      const url = this.isInnerAgent
        ? this.agentWebsites[0]
        : this.agentWebsites[1];
      // window.sessionStorage.getItem("frUrl") || this.agentWebsites[0];
      window.open(`//${url}?type=balanceUnlock`);
    },
  },
};
</script>

<style lang="less" scoped>
#balance-lock-modal {
  z-index: 20001;
  .mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
  }

  .message {
    width: 520px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
  }

  .header {
    height: 60px;
    line-height: 60px;
    background: #44475d;
    color: #ffffff;
    text-align: center;
    font-size: 26px;
  }

  .body {
    background: #ffffff;
    height: 160px;
    line-height: 160px;
    text-align: center;
    .content {
      width: 64%;
      word-wrap: unset !important;
      margin: 0 auto;
      padding-top: 50px;
      line-height: 30px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding-bottom: 30px;
    margin-top: -1px;

    .cancel,
    .confirm {
      width: 160px;
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 5px;
      margin: 0 10px;
      cursor: pointer;
    }

    .cancel {
      background: #ffffff;
      border: 1px solid #c7977c;
      color: #c7977c;
    }

    .confirm {
      background-image: linear-gradient(133deg, #c7977c 0%, #ad7a62 100%);
      border: 1px solid #c7977c;
      color: #ffffff;
    }
  }
}
</style>
