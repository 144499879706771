<template>
  <el-dialog
    :title="$t('app.label.messageTitleDetail')"
    :visible.sync="visible"
    style="height: 100%"
    class="msg-detail"
  >
    <el-form
      class="resize-height"
      ref="formMsgDetail"
      label-position="right"
      :status-icon="true"
      :model="formMsgDetail"
      label-width="100px"
    >
      <el-form-item :label="$t('app.label.msgDetailTitle')">
        <el-input type="text" v-model="formMsgDetail.property1" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('app.label.msgDetailContent')" class="verify">
        <el-input
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 16 }"
          v-model="formMsgDetail.property2"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="deleteMsg">{{
          $t("app.button.delete")
        }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import * as api from "@/api/api";
export default {
  name: "MessageDetailDialog",
  props: {
    value: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    formMsgDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    deleteMsg() {
      const params = {
        id: this.formMsgDetail.id,
        flag: -1,
      };
      api.modifyMsg(params, (res) => {
        let message = res.data;
        if (!message.fail) {
          this.$message({
            type: "success",
            message: this.$t("app.msg.success.delete"),
          });
        }
        this.$emit("input",false)
        this.$parent.openMessageDialog();
      });
    },
  },
};
</script>

<style>
</style>